import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {createEntity, getEntity, reset, updateEntity} from './region-description.reducer';
import {hasAnyPermission} from "app/shared/auth/permissions";
import {getAllByType as getCountries} from "app/entities/region/region.reducer";

export interface IRegionDescriptionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{
  id: string
}> {
}

export const RegionDescriptionUpdate = (props: IRegionDescriptionUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [countryId, setCountryId] = useState(null)
  const [countryName, setCountryName] = useState('')
  const {regionDescriptionEntity, countries, userCountryId, loading, updating, hasUpdateAccess} = props;

  const handleClose = () => {
    props.history.push('/region-description');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    if(countries && countries.length > 0) {
      const activeCountry = countries.find(country => country.id === regionDescriptionEntity.countryId);
      if(activeCountry) {
        setCountryName(activeCountry.name);
      }
    }
  }, [countries]);

  useEffect(() => {
    setCountryId(userCountryId);
  }, [regionDescriptionEntity, userCountryId]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...regionDescriptionEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.region.description.home.createOrEditLabel">{isNew ? 'Add' : 'Edit'} Region
            Description</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : regionDescriptionEntity} onSubmit={saveEntity}>
              <br/>
              {!isNew ? (
                <>
                  <AvGroup>
                    <Label for="region-description-id">ID</Label>
                    <AvInput id="region-description-id" type="text" className="form-control" name="id" required
                             readOnly/>
                  </AvGroup>
                  <AvGroup>
                    <Label for="region-description-type">Region Type</Label>
                    <AvInput id="region-description-type" type="text" className="form-control" name="regionType"
                             required readOnly/>
                  </AvGroup>
                  <AvGroup>
                    <Label for="region-description-country">Country</Label>
                    <AvInput id="region-description-country" type="text" className="form-control" name="countrName"
                             required value={countryName} readOnly/>
                  </AvGroup>
                </>
              ) : null}

              <br/>
              <div className="mt-3">

                <Row className="justify-content-left">
                  <Col md="6">
                    <AvGroup>
                      <Label id="enDesc " for="region-description-enDesc">
                        Region Description (English)
                      </Label>
                      <AvField
                        id="region-description-enDesc"
                        type="text"
                        name="enDesc"
                        disabled={!hasUpdateAccess}
                        validate={{
                          required: {value: true, errorMessage: 'This field is required.'},
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="stDesc" for="region-description-stDesc">
                        Region Description (Local Language)
                      </Label>
                      <AvField
                        id="region-description-stDesc"
                        type="text"
                        name="stDesc"
                        disabled={!hasUpdateAccess}
                        validate={{
                          required: {value: true, errorMessage: 'This field is required.'},
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
              </div>

              <br/>
              <Button tag={Link} id="cancel-save" to="/region-description" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={!hasUpdateAccess || updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  regionDescriptionEntity: storeState.regionDescription.entity,
  loading: storeState.regionDescription.loading,
  updating: storeState.regionDescription.updating,
  updateSuccess: storeState.regionDescription.updateSuccess,
  userCountryId: storeState.authentication.userCountryId,
  countries: storeState.region.countries,
  hasUpdateAccess: hasAnyPermission(storeState.authentication.permissions, ['ENTITIES_MODIFY']),
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getCountries,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegionDescriptionUpdate);
