import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, ITransferReason} from 'app/shared/model/transfer-reason.model';
import {IPayload} from "react-jhipster/src/type/redux-action.type";

export const ACTION_TYPES = {
  FETCH_REASON_LIST: 'transfer-reason/FETCH_REASON_LIST',
  FETCH_REASON: 'transfer-reason/FETCH_REASON',
  CREATE_REASON: 'transfer-reason/CREATE_REASON',
  UPDATE_REASON: 'transfer-reason/UPDATE_REASON',
  DELETE_REASON: 'transfer-reason/DELETE_REASON',
  RESET: 'transfer-reason/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITransferReason>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  deleteFailure: false
};

export type TransferReasonState = Readonly<typeof initialState>;

// Reducer

export default (state: TransferReasonState = initialState, action): TransferReasonState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REASON_LIST):
    case REQUEST(ACTION_TYPES.FETCH_REASON):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        deleteFailure: false,
      };
    case REQUEST(ACTION_TYPES.CREATE_REASON):
    case REQUEST(ACTION_TYPES.UPDATE_REASON):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_REASON):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        deleteFailure: false,
      };
    case FAILURE(ACTION_TYPES.FETCH_REASON_LIST):
    case FAILURE(ACTION_TYPES.FETCH_REASON):
    case FAILURE(ACTION_TYPES.CREATE_REASON):
    case FAILURE(ACTION_TYPES.UPDATE_REASON):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_REASON):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        deleteFailure: true,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REASON_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REASON):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_REASON):
    case SUCCESS(ACTION_TYPES.UPDATE_REASON):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_REASON):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        deleteFailure: false,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<ITransferReason>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false,
        deleteFailure: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/transfer-reason';

// Actions
export const getEntity: ICrudGetAction<ITransferReason> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_REASON,
    payload: axios.get<ITransferReason>(requestUrl),
  };
};

export const getEntities: (countryId, page?, size?, sort?) => IPayload<ITransferReason> | IPayloadResult<ITransferReason> = (countryId, page, size, sort) => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.FETCH_REASON_LIST,
    payload: axios.get<ITransferReason>(`${apiUrl}?countryId=${countryId}&cacheBuster=${new Date().getTime()}`),
  })
}

export const createEntity: ICrudPutAction<ITransferReason> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_REASON,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
};

export const updateEntity: ICrudPutAction<ITransferReason> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_REASON,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
};

export const deleteEntity: ICrudDeleteAction<ITransferReason> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  return await dispatch({
    type: ACTION_TYPES.DELETE_REASON,
    payload: axios.delete(requestUrl),
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
