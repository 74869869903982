import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities} from './transfer-reason.reducer';
import {IRegion} from "app/shared/model/region.model";
import {getAllByType as getCountries} from "app/entities/region/region.reducer";
import 'react-treelist/build/css/index.css';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';

export interface ITransferReasonProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const TransferReason = (props: ITransferReasonProps) => {
  const {transferReasonList, match, loading, userCountryId} = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>({})

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
    props.getEntities(userCountryId);
  }, [props.countries, userCountryId]);

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    props.getEntities(countryId);
  }

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === userCountryId;
  })


  return (
    <div>
      <h2 id="transferReason-heading">
        Transfer Reasons
        {(activeCountry && typeof activeCountry.id !== 'undefined') && (
          <Link to={`${match.url}/create`}
                className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus"/>
            &nbsp; Create new Transfer Reason
          </Link>
        )
        }
      </h2>

      <br/>
      {(regCountriesList && regCountriesList.length > 0) && (<AvForm>
          <AvGroup>
            <AvInput id="region-description-country" type="select" className="form-control"
                     name="region-description-country" value={activeCountry?.id} onChange={onCountryChange}>
              {regCountriesList
                ? regCountriesList.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))
                : null}
            </AvInput>
          </AvGroup>
        </AvForm>
      )}

      <br/>
      <div className="table-responsive">
        {transferReasonList && transferReasonList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>Id</th>
              <th>Reason (English)</th>
              <th>Reason (Local Language)</th>
              <th>Display Order</th>
              <th>Active Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {transferReasonList.map((transferReason, i) => (
              <tr key={`entity-${i}`}>
                <td>{transferReason.id}</td>
                <td>{transferReason.enTransferReason}</td>
                <td>{transferReason.stTransferReason}</td>
                <td>{transferReason.displayOrder}</td>
                <td>{transferReason.isActive ? 'Active' : 'Inactive'}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${transferReason.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">View/Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Transfer Reasons Found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({transferReason, authentication, region}: IRootState) => ({
  transferReasonList: transferReason.entities,
  loading: transferReason.loading,
  countries: region.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransferReason);
