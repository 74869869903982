import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Map,{googleMapsApiKey} from 'app/shared/model/Map';
import { IRootState } from 'app/shared/reducers';
import { getEntity, updateEntity } from './geofence-route.reducer';
import { getMMamaSettings as getSettings ,getMMamaSettingsById} from 'app/modules/administration/mmama-settings/mmama-settings.reducer';
import { displaySecondsAsHoursMinutes } from 'app/shared/util/date-utils';
import { IRoute } from 'app/shared/model/route.model';
import { RouteForm } from './geofence-route-update-modal';
import mapboxgl from 'mapbox-gl';
import {MAPBOX_ACCESS_TOKEN} from "app/config/constants";

export interface IRouteDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const GeofenceRouteDetail = (props: IRouteDetailProps) => {

  const { routeEntity, mmamaSettings } = props;
  const [ updatingRoute, setUpdatingRoute ] = useState(false);
  const [mapboxRouteObject, setMapboxRouteObject] = useState({});
  const [showRoute, setShowRoute] = useState(false);
  const [pathLocation, setPathLocation] = useState({
    startLocation : {latitude: 0,longitude: 0},
    endLocation : {latitude: 0,longitude: 0}
  });
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

  function calculateRoutePrice(distance: number): number {
    return distance * mmamaSettings.costKm;
  }

  const handleSaveRoute = (route: IRoute) => {
    props.updateEntity(route);
    setUpdatingRoute(false);
  }

  const handleUpdateModalClose = () => {
    setUpdatingRoute(false);
  }

  const handleUpdateRoute = () => {
    setUpdatingRoute(true);
  }

  useEffect(() => {
    props.getEntity(props.match.params.id);
   // props.getSettings();
   props.getMMamaSettingsById(1);
  }, []);

  useEffect(() => {
    const locationData = {...pathLocation};
    locationData.startLocation.latitude = routeEntity.startCouncil?.locationLat;
    locationData.startLocation.longitude = routeEntity.startCouncil?.locationLon;
    locationData.endLocation.latitude = routeEntity.endCouncil?.locationLat;
    locationData.endLocation.longitude = routeEntity.endCouncil?.locationLon;
    setPathLocation(locationData)
  }, [routeEntity])

  const getRouteDistance = async () => {
    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude};${pathLocation.endLocation.longitude},${pathLocation.endLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
      { method: 'GET' }
      );

    const json = await query.json();
    setMapboxRouteObject(json.routes[0]);
  }

  useEffect(() => {
      if(showRoute) {
      if (pathLocation.startLocation.latitude && pathLocation.endLocation.latitude &&
        pathLocation.startLocation.latitude !== 0 && pathLocation.endLocation.latitude !== 0) {
        getRouteDistance();
      }
    }
  }, [showRoute]);

  return (
    <div>
      { updatingRoute && (
        <RouteForm opened={true} routeEntity={routeEntity} mmamaSettings={mmamaSettings} onSave={handleSaveRoute} toggle={handleUpdateModalClose}/>
      )}
      <Row>
        <Col md="4">
          <h2>
          Geofence Route [<b>{routeEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="distance">Distance</span>
            </dt>
            <dd>{routeEntity.distance}</dd>
            <dt>
              <span id="durationSeconds">Duration (Minutes)</span>
            </dt>
            <dd>{displaySecondsAsHoursMinutes(routeEntity.durationSeconds)}</dd>
            <dt>
              <span id="computedPrice">Computed Price</span>
            </dt>
            <dd>
              {calculateRoutePrice(routeEntity.distance)}
            </dd>
            <dt>
              <span id="overridePrice">Override Price</span>
            </dt>
            <dd>{routeEntity.overridePrice}</dd>
            <dt>
              <span id="description">Description</span>
            </dt>
            <dd>{routeEntity.description ?? '-'}</dd>
            <dt>
              <span id="isActive">Active</span>
            </dt>
            <dd>{routeEntity.isActive ? 'true' : 'false'}</dd>
            <dt>Start</dt>
            <dd>{routeEntity.startCouncil ? routeEntity.startCouncil.name : '-'}</dd>

            <dt>End</dt>
            <dd>{routeEntity.endCouncil ? routeEntity.endCouncil.name : '-'}</dd>
          </dl>
          <Button tag={Link} to="/geofence-routes" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button
            onClick={() => {handleUpdateRoute()}}
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
          &nbsp;
          <Button
            onClick={() => setShowRoute(true)}
          >
            <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Show Route</span>
          </Button>
        </Col>
        <Col md='8'>
        <Map pathLocation={pathLocation} mapboxRouteObject={mapboxRouteObject} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ geofenceRoute, mmamaSettings }: IRootState) => ({
  routeEntity: geofenceRoute.entity,
  mmamaSettings: mmamaSettings.settingsDetail,
});

const mapDispatchToProps = {
  getEntity,
  getMMamaSettingsById,
  updateEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GeofenceRouteDetail);
