import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './symptom.reducer';

export interface ISymptomDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const SymptomDetail = (props: ISymptomDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const {symptomEntity} = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Symptom [<b>{symptomEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="emergencyType">Emergency Type</span>
          </dt>
          <dd>{symptomEntity.emergencyType}</dd>
          <dt>
            <span id="enCondition">Condition (English)</span>
          </dt>
          <dd>{symptomEntity.enCondition}</dd>
          <dt>
            <span id="stCondition">Condition (Sesotho)</span>
          </dt>
          <dd>{symptomEntity.stCondition}</dd>
          <dt>
            <span id="requiredFacilityLevel">Required Facility Level</span>
          </dt>
          <dd>{symptomEntity.requiredFacilityLevel}</dd>
          <dt>
            <span id="requiredVehicleLevel">Required Vehicle Level</span>
          </dt>
          <dd>{symptomEntity.requiredVehicleLevel}</dd>
        </dl>
        <Button tag={Link} to="/symptom" replace color="info">
          <FontAwesomeIcon icon="arrow-left"/> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/symptom/${symptomEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({symptom}: IRootState) => ({
  symptomEntity: symptom.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SymptomDetail);
