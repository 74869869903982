import {IRegion} from "app/shared/model/region.model";
import {RegionDescriptionType} from "app/shared/model/enumerations/region-description-type.model";

export interface IRegionDescription {
  id?: string;
  regionType?: RegionDescriptionType;
  enDesc?: string;
  stDesc?: string;
  countryId?: string;
  country?: IRegion;
}

export const defaultValue: Readonly<IRegionDescription> = {};
