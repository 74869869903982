import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RegionDescriptionUpdate from './region-description-update';
import RegionDescription from "app/entities/region-description/region-description";

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/create`} component={RegionDescriptionUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RegionDescriptionUpdate}/>
      <ErrorBoundaryRoute path={match.url} component={RegionDescription}/>
    </Switch>
  </>
);

export default Routes;
