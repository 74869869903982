import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Form, FormGroup, Input, Label, Row, Table} from 'reactstrap';
import {getSortState, JhiItemCount, JhiPagination} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities, getVillagesByName} from './village.reducer';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';
import {SortableHeaderRow} from "app/shared/tables/tables";
import {IRegion} from "app/shared/model/region.model";
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';

export interface IVillageProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const getVillageName = (id, villageList) => {

  const village = villageList.find(v => v.id === id);
  return !village ?
    {short: '', full: '', council: '', district: '', latitude: 0, longitude: 0, activated: ""}
    :
    {
      short: village.name,
      council: village?.council?.name,
      district: village?.council?.parent?.name,
      country: village?.council?.parent,
      latitude: village?.locationLat,
      longitude: village?.locationLon,
      activated: village?.activated,
      full: (village.name + ' / ' + village?.council?.name + ' / ' + village?.council?.parent?.name)
    };
}

export const Village = (props: IVillageProps) => {
  const [activeCountry, setActiveCountry] = useState<IRegion>({})

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = (countryId: string) => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, countryId);
  };

  const sortEntities = (countryId: string) => {
    getAllEntities(countryId);
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    sortEntities(countryId)
  }

  useEffect(() => {
    sortEntities(activeCountry?.id);
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeCountry]);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === props.userCountryId)
    setActiveCountry(ac)
  }, [props.countries, props.userCountryId]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }

  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const {villageList, match, loading, totalItems} = props;

  const defaultValues = {
    villageCountry: activeCountry,

  };

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  })
  return (
    <div>
      <h2 id="village-heading">
        Villages
        {activeCountry && (<AvForm model={defaultValues}>
            <AvGroup>
              <AvInput id="village-countries" type="select" className="form-control" name="villageCountry"
                       onChange={onCountryChange}
                       value={activeCountry.id}
              >
                {regCountriesList
                  ? regCountriesList.map(country => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))
                  : null}
              </AvInput>
            </AvGroup>
          </AvForm>
        )
        }

        {(activeCountry && typeof activeCountry.id !== 'undefined') && (
          <Link to={`${match.url}/new?country_id=${activeCountry.id}`}
                className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus"/>
            &nbsp; Create new Village
          </Link>
        )
        }
      </h2>
      <div>
        <Form>
          <FormGroup>
            <Label for="villageByName">Search by village name</Label>
            <Input type="input" name="searchByVilageName"
                   onChange={(e) => {
                     const serachString = e.target.value;
                     if (e.target.value) {
                       props.getVillagesByName(serachString)
                     } else {
                       if (activeCountry) {
                         sortEntities(activeCountry.id);
                       }
                     }

                   }}

                   id="searchByVilageName"
                   placeholder="village name"/>
          </FormGroup>
        </Form>

      </div>


      <div className="table-responsive">
        {villageList && villageList.filter((item: any) => item?.council?.parent?.parent?.parent?.id === activeCountry.id).length > 0 ? (
          <Table responsive>
            <thead>
            <SortableHeaderRow sort={sort} cols={[
              ['name', 'Name'],
              ['pickup_location', 'Pickup Location'],
              ['council', 'Council'],
              ['', 'District'],
              ['location_lat', 'Latitude'],
              ['location_lon', 'Longitude'],
              ['is_active', 'Status'],
              ['contact_name', 'Contact Name'],
              ['phone_number', 'Phone Number']

            ]}/>
            </thead>
            <tbody>
            {villageList.filter((item: any) => item?.council?.parent?.parent?.parent?.id === activeCountry.id).map((village, i) => (
              <tr key={`entity-${i}`}>
                <td>{village.name}</td>
                <td>{village.pickupLocation}</td>
                <td>{village?.council ? village.council.name : ''}</td>
                <td>{village?.council?.parent ? village.council?.parent?.name : ''}</td>

                <td>{village.locationLat}</td>
                <td>{village.locationLon}</td>
                <td>{village.activated ? 'Active' : 'Inactive'}</td>
                <td>{village.contactName}</td>
                <td>{village.phoneNumber}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${village.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye"/> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${village.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Villages found</div>
        )}
      </div>
      {props.totalItems ? (
        <div
          className={villageList && villageList.filter((item: any) => item?.council?.parent?.parent?.parent?.id === activeCountry.id).length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems}
                          itemsPerPage={paginationState.itemsPerPage}/>
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({village, authentication}: IRootState) => ({
  villageList: village.entities,
  loading: village.loading,
  totalItems: village.totalItems,
  countries: village.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getVillagesByName
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Village);
