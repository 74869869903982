import {combineReducers} from 'redux';
import {loadingBarReducer as loadingBar} from 'react-redux-loading-bar';

import authentication, {AuthenticationState} from './authentication';
import applicationProfile, {ApplicationProfileState} from './application-profile';
import administration, {AdministrationState} from 'app/modules/administration/administration.reducer';
import userManagement, {UserManagementState} from 'app/modules/administration/user-management/user-management.reducer';
import password, {PasswordState} from 'app/modules/account/password/password.reducer';
import passwordReset, {PasswordResetState} from 'app/modules/account/password-reset/password-reset.reducer';
import journey, {JourneyState} from 'app/entities/journey/journey.reducer';
import route, {RouteState} from 'app/entities/route/route.reducer';
import symptom, {SymptomState} from 'app/entities/symptom/symptom.reducer';
import followup, {FollowupState} from 'app/entities/followup/followup.reducer';
import condition, {ConditionState} from 'app/entities/condition/condition.reducer';

import healthcareFacility, {
  HealthcareFacilityState
} from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import healthcareFacilityLevel, {
  HealthcareFacilityLevelState
} from 'app/entities/healthcare-facility-level/healthcare-facility-level.reducer';
import driver, {DriverState} from 'app/entities/driver/driver.reducer';
import country, {CountryState} from 'app/entities/country/country.reducer';
import district, {DistrictState} from 'app/entities/district/district.reducer';
import council, {CouncilState} from 'app/entities/council/council.reducer';
import village, {VillageState} from 'app/entities/village/village.reducer';
import payment, {PaymentState} from 'app/entities/payment/payment.reducer';
// import headerData, {HeaderState} from 'app/shared/layout/header/header.reducer';
import ambulance, {AmbulanceState} from 'app/entities/ambulance/ambulance.reducer';
import incidentReport, {IncidentReportState} from 'app/entities/incident-report/incident-report.reducer';
import mmamaSettings, {MMamaSettingsState} from 'app/modules/administration/mmama-settings/mmama-settings.reducer';
import region, {RegionState} from 'app/entities/region/region.reducer';
import dispatchCenter, {DispatchCenterState} from 'app/entities/dispatchCenter/dispatch-center.reducer';
import continent, {ContinentState} from 'app/entities/continent/continent.reducer';
import reportSettings, {ReportSettingsState} from 'app/modules/administration/report-settings/report-settings.reducer';
import permissionSettings, {
  PermissionSettingsState
} from 'app/modules/administration/permission-settings/permission-settings.reducer';
import paymentBreakdown, {
  PaymentBreakDownState
} from 'app/modules/administration/payment-breakdown/payment-breakdown.reducer';
import paymentApprovalSettings, {
  PaymentApprovalSettingsState
} from 'app/modules/administration/payment-approval-settings/payment-approval-settings.reducer';
import villageRoute, {villageRouteState} from 'app/entities/village-route/village-route.reducer';
import geofenceRoute, {geofenceRouteState} from 'app/entities/geofence-route/geofence-route.reducer';
import vehicleType, {VehicleTypeState} from 'app/entities/vehicleType/vehicleType.reducer';
import rejectionReasonDriver, {
  RejectionReasonDriverState
} from 'app/entities/rejection-reason/rejection-reason.reducer';
import rejectionReasonHF, {RejectionReasonHFState} from 'app/entities/rejection-reason-hf/rejection-reason.reducer';
import transferReason, {TransferReasonState} from 'app/entities/transfer-reason/transfer-reason.reducer';
import caseManagement, {CaseManagementState} from 'app/entities/case-management/case-management.reducer';
import meDashboard, {MeDashboardState} from 'app/modules/programDashboard/dashboards/meDashboard/me-dashboard.reducer';
import filterOption, {
  FilterOptionState,
} from 'app/modules/programDashboard/dashboardWrapper/sidebar/subMenu/childSubMenu/child-sub-menu.reducer';
import caseManagementDashboard, {
  CaseManagementDashboardState
} from "app/modules/programDashboard/dashboards/caseManagementDashboard/case-management-dashboard.reducer";
import regionDescription, {RegionDescriptionState} from "app/entities/region-description/region-description.reducer";


export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly journey: JourneyState;
  readonly route: RouteState;
  readonly symptom: SymptomState;
  readonly followup: FollowupState;
  readonly condition: ConditionState;
  readonly healthcareFacility: HealthcareFacilityState;
  readonly healthcareFacilityLevel: HealthcareFacilityLevelState;
  readonly driver: DriverState;
  readonly country: CountryState;
  readonly district: DistrictState;
  readonly council: CouncilState;
  readonly village: VillageState;
  readonly payment: PaymentState;
  // readonly headerData: HeaderState;
  readonly ambulance: AmbulanceState;
  readonly incidentReport: IncidentReportState;
  readonly mmamaSettings: MMamaSettingsState;
  readonly region: RegionState;
  readonly regionDescription: RegionDescriptionState;
  readonly dispatchCenter: DispatchCenterState;
  readonly continent: ContinentState;
  readonly reportSettings: ReportSettingsState;
  readonly permissionSettings: PermissionSettingsState;
  readonly paymentBreakdown: PaymentBreakDownState;
  readonly paymentApprovalSettings: PaymentApprovalSettingsState;
  readonly villageRoute: villageRouteState;
  readonly geofenceRoute: geofenceRouteState;
  readonly loadingBar: any;
  readonly vehicleType: VehicleTypeState;
  readonly rejectionReasonDriver: RejectionReasonDriverState;
  readonly rejectionReasonHF: RejectionReasonHFState;
  readonly transferReason: TransferReasonState;
  readonly caseManagement: CaseManagementState;
  readonly meDashboard: MeDashboardState;
  readonly caseManagementDashboard: CaseManagementDashboardState;
  readonly filterOption: FilterOptionState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  passwordReset,
  password,
  mmamaSettings,
  reportSettings,
  journey,
  route,
  symptom,
  followup,
  condition,
  healthcareFacility,
  healthcareFacilityLevel,
  driver,
  country,
  district,
  council,
  village,
  payment,
  ambulance,
  incidentReport,
  region,
  regionDescription,
  continent,
  dispatchCenter,
  loadingBar,
  permissionSettings,
  paymentBreakdown,
  paymentApprovalSettings,
  villageRoute,
  geofenceRoute,
  vehicleType,
  rejectionReasonDriver,
  rejectionReasonHF,
  transferReason,
  caseManagement,
  meDashboard,
  caseManagementDashboard,
  filterOption,
});

export default rootReducer;
