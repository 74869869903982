import React, {useState} from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import {IMMamaSettings} from 'app/shared/model/mmama-settings-model';
import {fromCents, toCents, toPercent, validateCentsString} from 'app/shared/util/money-utils';

interface IOverrideModalProps {
  opened: boolean;
  toggle: () => void;
  defaultAmount: number;
  paymentRequestedPrice: number;
  onSave: (number) => void;
  settings: IMMamaSettings
}

const OverridePriceModal = ({
                              opened,
                              toggle,
                              onSave,
                              defaultAmount,
                              paymentRequestedPrice,
                              settings
                            }: IOverrideModalProps) => {

  const [amount, setAmount] = useState<number>(defaultAmount);
  const [amountValid, setAmountValid] = useState(true);

  const calculateMaxAmount = (): number => {
    // defaultAmount was already converted from cents e.g. 12301 -> 123.01; set back to 12301
    const amountInCents = toCents(paymentRequestedPrice);
    const maxAmount = Math.floor(amountInCents + (amountInCents * toPercent(settings.paymentEditThreshold)));
    return fromCents(maxAmount);
  }

  const onAmountChange = (e) => {
    const {target} = e;
    setAmount(target.value);
    setAmountValid(validateCentsString(target.value) && target.value > 0 && target.value <= calculateMaxAmount());
    console.log("amount valid: {}", (amount > 0));
    console.log("amount less than max: {}", (amount < calculateMaxAmount()));
  }

  const onSubmit = () => {
    onSave(amount);
    toggle();
  }

  return (
    <Modal isOpen={opened} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Payment</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="new-amount">New Amount</Label>
            <Input
              onChange={onAmountChange}
              value={amount}
              name="number"
              type="number"
              min={0}
              max={calculateMaxAmount()}
              id="new-amount"
              valid={amountValid}
              invalid={!amountValid}
              required
            />
            <FormFeedback tooltip>New Amount must be greater than 0 and lower than max amount</FormFeedback>
            <FormText>Max amount: {calculateMaxAmount().toFixed(2)} (Original Requested
              Amount: {paymentRequestedPrice.toFixed(2)})</FormText>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>Cancel</Button>{' '}
        <Button color="primary" onClick={onSubmit} disabled={!amountValid}>Edit</Button>
      </ModalFooter>
    </Modal>
  );
}

export default OverridePriceModal;
