import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IRegionDescription} from 'app/shared/model/region-description.model';
import {IPayload} from "react-jhipster/src/type/redux-action.type";

export const ACTION_TYPES = {
  FETCH_DESCRIPTION_LIST: 'region-description/FETCH_DESCRIPTION_LIST',
  FETCH_DESCRIPTION: 'region-description/FETCH_DESCRIPTION',
  CREATE_DESCRIPTION: 'region-description/CREATE_DESCRIPTION',
  UPDATE_DESCRIPTION: 'region-description/UPDATE_DESCRIPTION',
  DELETE_DESCRIPTION: 'region-description/DELETE_DESCRIPTION',
  RESET: 'region-description/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRegionDescription>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  deleteFailure: false
};

export type RegionDescriptionState = Readonly<typeof initialState>;

// Reducer

export default (state: RegionDescriptionState = initialState, action): RegionDescriptionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DESCRIPTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DESCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        deleteFailure: false,
      };
    case REQUEST(ACTION_TYPES.CREATE_DESCRIPTION):
    case REQUEST(ACTION_TYPES.UPDATE_DESCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_DESCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        deleteFailure: false,
      };
    case FAILURE(ACTION_TYPES.FETCH_DESCRIPTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DESCRIPTION):
    case FAILURE(ACTION_TYPES.CREATE_DESCRIPTION):
    case FAILURE(ACTION_TYPES.UPDATE_DESCRIPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_DESCRIPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        deleteFailure: true,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DESCRIPTION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DESCRIPTION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DESCRIPTION):
    case SUCCESS(ACTION_TYPES.UPDATE_DESCRIPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DESCRIPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        deleteFailure: false,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IRegionDescription>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false,
        deleteFailure: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/region-description';

// Actions
export const getEntity: ICrudGetAction<IRegionDescription> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DESCRIPTION,
    payload: axios.get<IRegionDescription>(requestUrl),
  };
};

export const getEntities: (countryId, page?, size?, sort?) => IPayload<IRegionDescription> | IPayloadResult<IRegionDescription> = (countryId, page, size, sort) => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.FETCH_DESCRIPTION_LIST,
    payload: axios.get<IRegionDescription>(`${apiUrl}?countryId=${countryId}&cacheBuster=${new Date().getTime()}`),
  })
}

export const createEntity: ICrudPutAction<IRegionDescription> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_DESCRIPTION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
};

export const updateEntity: ICrudPutAction<IRegionDescription> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_DESCRIPTION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
};

export const deleteEntity: ICrudDeleteAction<IRegionDescription> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  return await dispatch({
    type: ACTION_TYPES.DELETE_DESCRIPTION,
    payload: axios.delete(requestUrl),
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
