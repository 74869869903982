import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TransferReasonUpdate from './transfer-reason-update';
import TransferReason from "app/entities/transfer-reason/transfer-reason";

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/create`} component={TransferReasonUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TransferReasonUpdate}/>
      <ErrorBoundaryRoute path={match.url} component={TransferReason}/>
    </Switch>
  </>
);

export default Routes;
