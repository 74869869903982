import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import { createEntity, getEntity, reset, updateEntity } from './healthcare-facility-level.reducer';
import { IRegion } from 'app/shared/model/region.model';
import { hasAnyPermission } from 'app/shared/auth/permissions';

export interface IHealthcareFacilityLevelUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{
  countryId: string,
  level: string
}> {
}

export const HealthcareFacilityLevelUpdate = (props: IHealthcareFacilityLevelUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || (!props.match.params.countryId && !props.match.params.level));
  const [activeCountry, setActiveCountry] = useState<IRegion>({});
  const { healthcareFacilityEntityLevel, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/healthcare-facility-level' + props.location.search);
  };

  const onCountryChange = (event) => {
    const countryId = event.target.value;
    const newActiveCountry = props.countries.find(country => country.id === countryId);
    setActiveCountry(newActiveCountry);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.countryId, props.match.params.level);
    }

    props.getCountries();
  }, []);

  useEffect(() => {
    if (isNew) {
      const ac = props.countries.find(country => country.id === (props.isAdmin ? props.countries[0].id : props.userCountryId));
      setActiveCountry(ac);
    }
  }, [props.countries]);

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  });

  useEffect(() => {
    console.log('healthcareFacilityEntityLevel', healthcareFacilityEntityLevel);
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...healthcareFacilityEntityLevel,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.healthcareFacilityLevel.home.createOrEditLabel">Create or edit a Healthcare Facility
            Level</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : healthcareFacilityEntityLevel} onSubmit={saveEntity}>

              {regCountriesList && regCountriesList.length > 0 && (
                <AvGroup>
                  <Label for="hf-country">Country</Label>
                  <AvInput
                    id="hf-country"
                    type="select"
                    className="form-control"
                    name="countryId"
                    disabled={isNew ? false : true}
                    value={isNew ? activeCountry?.id : healthcareFacilityEntityLevel?.countryId?.id}
                    onChange={onCountryChange}>
                    {regCountriesList && regCountriesList.length > 0
                      && regCountriesList.map(country => (
                        <option value={country?.id} key={country?.id}>
                          {country.name}
                        </option>
                      ))}
                  </AvInput>
                </AvGroup>
              )}

              <AvGroup>
                <Label id="levelLabel" for="healthcare-facility-level-level">
                  Level
                </Label>
                <AvField
                  id="healthcare-facility-level-level"
                  type="number"
                  className="form-control"
                  name="level"
                  disabled={isNew ? false : true}
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    number: { value: true, errorMessage: 'This field should be a number.' }
                  }}
                  min={0}
                  step={1}
                />
              </AvGroup>

              <AvGroup>
                <Label id="enDescriptionLabel" for="healthcare-facility-level-enDescriptionLabel">
                  En_Description
                </Label>
                <AvField
                  id="healthcare-facility-level-enDescriptionLabel"
                  type="text"
                  name="en_description"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>

              <AvGroup>
                <Label id="nameLabel" for="healthcare-facility-level-stDescriptionLabel">
                  St_Description
                </Label>
                <AvField
                  id="healthcare-facility-level-stDescriptionLabel"
                  type="text"
                  name="st_description"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>

              <Button tag={Link} id="cancel-save" to="/healthcare-facility-level" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  countries: storeState.region.countries,
  healthcareFacilityEntityLevel: storeState.healthcareFacilityLevel.entity,
  loading: storeState.healthcareFacilityLevel.loading,
  updating: storeState.healthcareFacilityLevel.updating,
  updateSuccess: storeState.healthcareFacilityLevel.updateSuccess,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getCountries,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareFacilityLevelUpdate);
