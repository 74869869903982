import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPaymentApprovalSettings } from 'app/shared/model/paymentApprovalSettings.model';
import { IRegion } from 'app/shared/model/region.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';

export const ACTION_TYPES = {
  FETCH_PAYMENT_APPROVAL_SETTINGS: 'paymentApprovalSettings/FETCH_PAYMENT_APPROVAL_SETTINGS',
  FETCH_APPROVAL_SETTINGS: 'paymentApprovalSettings/FETCH_APPROVAL_SETTINGS',
  CREATE_APPROVAL_SETTINGS: 'paymentApprovalSettings/CREATE_APPROVAL_SETTINGS',
  UPDATE_APPROVAL_SETTINGS: 'paymentApprovalSettings/UPDATE_APPROVAL_SETTINGS',
  DELETE_APPROVAL_SETTINGS: 'paymentApprovalSettings/DELETE_APPROVAL_SETTINGS',
  RESET: 'paymentApprovalSettings/RESET',
  SET_ACTIVE_COUNTRY: 'paymentApprovalSettings/SET_ACTIVE_COUNTRY',
  FETCH_ALL_COUNTRIES: 'paymentApprovalSettings/FETCH_ALL_COUNTRIES',
  FETCH_ROLES: 'paymentApprovalSettings/FETCH_ROLES'
};

const initialState = {
  loading: false,
  errorMessage: null,
  paymentApprovalSettingsList: [] as ReadonlyArray<IPaymentApprovalSettings>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  countries: [] as ReadonlyArray<IRegion>,
  roles: [] as any[]
};

export type PaymentApprovalSettingsState = Readonly<typeof initialState>;

// Reducer

export default (state: PaymentApprovalSettingsState = initialState, action): PaymentApprovalSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAYMENT_APPROVAL_SETTINGS):
    case REQUEST(ACTION_TYPES.FETCH_ALL_COUNTRIES):
    case REQUEST(ACTION_TYPES.FETCH_ROLES):
    case REQUEST(ACTION_TYPES.FETCH_APPROVAL_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_APPROVAL_SETTINGS):
    case REQUEST(ACTION_TYPES.UPDATE_APPROVAL_SETTINGS):
    case REQUEST(ACTION_TYPES.DELETE_APPROVAL_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PAYMENT_APPROVAL_SETTINGS):
    case FAILURE(ACTION_TYPES.FETCH_APPROVAL_SETTINGS):
    case FAILURE(ACTION_TYPES.CREATE_APPROVAL_SETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_APPROVAL_SETTINGS):
    case FAILURE(ACTION_TYPES.DELETE_APPROVAL_SETTINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload?.response?.data?.title || 'An error occurred',
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENT_APPROVAL_SETTINGS):
      return {
        ...state,
        loading: false,
        paymentApprovalSettingsList: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPROVAL_SETTINGS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APPROVAL_SETTINGS):
    case SUCCESS(ACTION_TYPES.UPDATE_APPROVAL_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_APPROVAL_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        loading: false,
        countries: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state,
        roles: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entity: defaultValue,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/payment-approval-settings';


export const getEntities: (page?, size?, sort?) => IPayload<IPaymentApprovalSettings> | IPayloadResult<IPaymentApprovalSettings> = (page, size, sort) => async dispatch => {
    const paymentApprovalSettings = await dispatch({
    type: ACTION_TYPES.FETCH_PAYMENT_APPROVAL_SETTINGS,
    payload: axios.get<IPaymentApprovalSettings>(`${'api/payment-approval-settings'}?cacheBuster=${new Date().getTime()}`)
  });

  return paymentApprovalSettings;
};

export const getEntity: ICrudGetAction<IPaymentApprovalSettings> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APPROVAL_SETTINGS,
    payload: axios.get<IPaymentApprovalSettings>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPaymentApprovalSettings> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_APPROVAL_SETTINGS,
    payload: axios.post('api/add-payment-approval-settings', cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IPaymentApprovalSettings> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APPROVAL_SETTINGS,
    payload: axios.post('api/edit-payment-approval-settings', cleanEntity(entity))
  });
  return result;
};

export const moveEntityStageUp: ICrudPutAction<IPaymentApprovalSettings> = id => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_APPROVAL_SETTINGS,
    payload: axios.put(`${apiUrl}/${id}/move-up`)
  });
}

export const moveEntityStageDown: ICrudPutAction<IPaymentApprovalSettings> = id => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_APPROVAL_SETTINGS,
    payload: axios.put(`${apiUrl}/${id}/move-down`)
  });
}

export const deleteEntity: ICrudDeleteAction<IPaymentApprovalSettings> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_APPROVAL_SETTINGS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getRoles = () => ({
  type: ACTION_TYPES.FETCH_ROLES,
  payload: axios.get(`${apiUrl}/roles`)
});
