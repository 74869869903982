import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getAllFacility } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { getVillagesByCountry as getVillages } from 'app/entities/village/village.reducer';
import { getEntities as getVehicleTypes } from 'app/entities/vehicleType/vehicleType.reducer';
import { createEntity, getEntity, reset, updateEntity } from './driver.reducer';
import { getVillageName } from 'app/entities/village/village';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import { IRegion } from 'app/shared/model/region.model';
import { hasAnyPermission } from 'app/shared/auth/permissions';

export interface IDriverUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const neighborVillageSectionStyle = {
  border: '5px solid #e0e0e0',
  padding: '1em',
  marginBottom: '1em'
};

const neighborVillageLabelStyle = {
  marginBottom: '0.75em',
  display: 'flex',
  justifyContent: 'space-between'
};

const neighborVillageEntryRowStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

const addNeighborVillageButtonStyle = {
  fontSize: 'smaller'
};

const neighborVillageRemoveButtonStyle = {
  width: '10em',
  margin: '0.3em 0.3em 0.3em 1em',
  paddingLeft: '1em',
  fontSize: 'smaller'
};
const radios = [
  { name: 'Active', value: true },
  { name: 'Disabled', value: false }
];
export const DriverUpdate = (props: IDriverUpdateProps) => {
  const { driver, villages, loading, updating, villagesLoading, vehicleTypes } = props;
  let { allHealthfacility } = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>({});
  allHealthfacility = activeCountry ? allHealthfacility.filter(el => el.council.parent?.parent?.parent?.id === activeCountry.id) : [];

  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [neighborVillageIds, setNeighborVillageIds] = useState([]);

  const [neighborHfIds, setNeighborHfIds] = useState([]);

  const [canAddNeighborVillage, setCanAddNeighborVillage] = useState(true);
  const [canAddNeighborHf, setCanAddNeighborHf] = useState(true);

  const [activeStatus, setActiveStatus] = useState(true);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);

  const getHfName = (id, hfList) => {

    const hf = hfList.find(v => v.id === id);
    return !hf ?
      { short: '', full: '', council: '', district: '' }
      :
      {
        short: hf.name,
        council: hf?.council?.name,
        district: hf?.council?.parent?.name,
        full: (hf.name + ' / ' + hf?.council?.name + ' / ' + hf?.council?.parent?.name)
      };
  };
  const hfNames = allHealthfacility ?
    allHealthfacility.map(hf => ({
      ...getHfName(hf.id, allHealthfacility),
      id: hf.id
    })) : [];

  const villageNames = villages ?
    villages.map(village => ({
      ...getVillageName(village.id, villages),
      id: village.id
    })) : [];

  const handleClose = () => {
    props.history.push('/driver' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    }
    props.getCountries();
  }, []);

  useEffect(() => {
    if (isNew) {
      const ac = props.countries.find(country => country.id === (props.isAdmin ? props.countries[0].id : props.userCountryId));
      setActiveCountry(ac);
      props.getVillages(undefined, undefined, undefined, ac?.id);
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getAllFacility();
    props.getVehicleTypes();
  }, [props.countries]);

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  });

  useEffect(() => {
    if (vehicleTypes?.length > 0) {
      const filteredTypes = vehicleTypes.filter((item: any) => item.countryId === activeCountry.id);
      setVehicleTypeList(filteredTypes);
    }
  }, [vehicleTypes, activeCountry]);

  useEffect(() => {
    const nIds = props?.driver?.neighborVillageIds ? [...props.driver.neighborVillageIds] : [];
    setNeighborVillageIds(nIds);

    const hfIds = props?.driver?.neighborHealthcareFacilityIds ? [...props.driver.neighborHealthcareFacilityIds] : [];
    setNeighborHfIds(hfIds);

    const driverStatus = props?.driver?.activated;
    setActiveStatus(driverStatus);
    const driverCountry = props.countries.find(country => country.id === driver?.countryId);

    if (driverCountry) {
      setActiveCountry(driverCountry);
      props.getVillages(undefined, undefined, undefined, driver?.countryId);
    }
  }, [props.driver]);

  const onCountryChange = (event) => {
    const countryId = event.target.value;
    const newActiveCountry = props.countries.find(country => country.id === countryId);
    setActiveCountry(newActiveCountry);
    props.getVillages(undefined, undefined, undefined, countryId);
    const filteredTypes = vehicleTypes.filter((item: any) => item.countryId === countryId);
    setVehicleTypeList(filteredTypes);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    setCanAddNeighborVillage((neighborVillageIds.length === 0) || neighborVillageIds[neighborVillageIds.length - 1]);
  }, [neighborVillageIds]);

  useEffect(() => {
    setCanAddNeighborHf((neighborHfIds.length === 0) || neighborHfIds[neighborHfIds.length - 1]);
  }, [neighborHfIds]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...driver,
        ...values,
        activated: activeStatus,
        neighborVillageIds,
        neighborHealthcareFacilityIds: neighborHfIds,
        vehicleType: vehicleTypes.find(item => item.id === values.vehicleType),
        driverType: 'CAR'
      };

      delete entity.journeyIds;
      delete entity.homeVillage;
      delete entity.formNeighborVillageIds;

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const addNeighborVillage = () => {
    setNeighborVillageIds(neighborVillageIds.concat(undefined));
  };

  const addNeighborHf = () => {
    setNeighborHfIds(neighborHfIds.concat(undefined));
  };


  const removeNeighborVillage = (i) => {
    const newNeighborVillageIds = neighborVillageIds.slice();
    newNeighborVillageIds.splice(i, 1);
    setNeighborVillageIds(newNeighborVillageIds);
  };
  const removeNeighborHf = (i) => {
    const newNeighborHfIds = neighborHfIds.slice();
    newNeighborHfIds.splice(i, 1);
    setNeighborVillageIds(newNeighborHfIds);
  };


  const neighborVillageChange = (changedIndex, newValue) => {
    const newNeighborVillageIds = neighborVillageIds.map((val, index) => {
      return (index === changedIndex) ? newValue : val;
    });
    setNeighborVillageIds(newNeighborVillageIds);
  };

  const neighborHfChange = (changedIndex, newValue) => {
    const newNeighborHfIds = neighborHfIds.map((val, index) => {
      return (index === changedIndex) ? newValue : val;
    });
    setNeighborHfIds(newNeighborHfIds);
  };


  const villageOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined }]) : [];
    result = result.concat(villageNames);
    return result;
  };

  const hfOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined }]) : [];
    result = result.concat(hfNames);
    return result;
  };

  const neighborVillageFields = () => {
    const result = [];
    neighborVillageIds.forEach((villageId, i) => {
      result.push(
        <div style={neighborVillageEntryRowStyle}>
          <div style={{ flex: '1' }}>
            <VillageAvTypeahead
              onChange={
                (value) => neighborVillageChange(i, value)
              }
              labelKey="name"
              valueKey="id"
              data={villageOptionList(!villageId)}
              placeholder={!villageId ? '' : getVillageName(villageId, villages).full}
              id={'neighbor-village-' + i + '-typeahead'}
              loading={villagesLoading}
              value={villageId || ''}
              input={{
                id: 'neighbor-village-' + i,
                name: 'formNeighborVillageValues[' + i + ']',
                required: true
              }}
            />
          </div>
          <Button style={neighborVillageRemoveButtonStyle} color="danger" id={`remove-neighbor-village-${i}`}
                  onClick={() => removeNeighborVillage(i)}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Remove
          </Button>
        </div>
      );
    });
    return result;
  };


  const neighborHfFields = () => {
    const result = [];
    neighborHfIds.forEach((hfId, i) => {
      result.push(
        <div style={neighborVillageEntryRowStyle}>
          <div style={{ flex: '1' }}>
            <VillageAvTypeahead
              onChange={
                (value) => neighborHfChange(i, value)
              }
              labelKey="name"
              valueKey="id"
              data={hfOptionList(!hfId)}
              placeholder={!hfId ? '' : getHfName(hfId, props.allHealthfacility).full}
              id={'neighbor-village-' + i + '-typeahead'}
              loading={villagesLoading}
              value={hfId || ''}
              input={{
                id: 'neighbor-hf-' + i,
                name: 'formNeighborhfValues[' + i + ']',
                required: true
              }}
            />
          </div>
          <Button style={neighborVillageRemoveButtonStyle} color="danger" id={`remove-neighbor-hf-${i}`}
                  onClick={() => removeNeighborHf(i)}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Remove
          </Button>
        </div>
      );
    });
    return result;
  };


  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.driver.home.createOrEditLabel">Create or edit a Driver</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : driver} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="driver-id">ID</Label>
                  <AvInput id="driver-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}

              <AvGroup>
                <Label id="lastNameLabel" for="driver-lastName">
                  Active Status
                </Label>
                <ButtonGroup style={{ marginLeft: '30px' }}>

                  <Button
                    color={activeStatus ? 'primary' : 'secondary'}
                    value={activeStatus}
                    onClick={(e) => {
                      setActiveStatus(true);
                    }}
                  >
                    Active
                  </Button>


                  <Button
                    color={!activeStatus ? 'primary' : 'secondary'}
                    value={activeStatus}
                    onClick={() => {
                      setActiveStatus(false);
                    }}
                  >
                    Inactive
                  </Button>

                </ButtonGroup>
              </AvGroup>
              {regCountriesList && regCountriesList.length > 0 && (
                <AvGroup>
                  <Label for="hf-country">Country</Label>
                  <AvInput id="hf-country" type="select" className="form-control" name="country"
                           disabled={isNew ? false : true}
                           value={activeCountry?.id} onChange={onCountryChange}>
                    {regCountriesList
                      ? regCountriesList.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
              )
              }
              <AvGroup>
                <Label id="firstNameLabel" for="driver-firstName">
                  First Name
                </Label>
                <AvField
                  id="driver-firstName"
                  type="text"
                  name="firstName"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="driver-lastName">
                  Last Name
                </Label>
                <AvField
                  id="driver-lastName"
                  type="text"
                  name="lastName"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>


              <AvGroup>
                <Label id="vehicleTypeLabel" for="driver-vehicleType">
                  Vehicle Type
                </Label>
                <AvInput
                  id="driver-vehicleType"
                  type="select"
                  className="form-control"
                  name="vehicleType"
                  value={(!isNew && driver.vehicleType?.id) || vehicleTypeList[0]?.id}
                >
                  {vehicleTypeList.map((item: any, index) => (
                    <option value={item.id} key={index}>{item.enType}</option>
                  ))}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="phoneNumberLabel" for="driver-phoneNumber">
                  Phone Number
                </Label>
                <AvField
                  id="driver-phoneNumber"
                  type="text"
                  name="phoneNumber"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 15, errorMessage: 'Maximum length is 15 characters' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="nationalIdLabel" for="driver-nationalId">
                  National ID / Passport Number
                </Label>
                <AvField
                  id="driver-nationalId"
                  type="text"
                  name="nationalId"
                />
              </AvGroup>
              <AvGroup>
                <Label id="birthDateLabel" for="driver-birthDate">
                  Birth Date
                </Label>
                <AvField
                  id="driver-birthDate"
                  type="date"
                  className="form-control"
                  name="birthDate"
                />
              </AvGroup>
              <AvGroup>
                <Label id="licenseNumberLabel" for="driver-licenseNumber">
                  Driver License Number
                </Label>
                <AvField
                  id="driver-licenseNumber"
                  type="text"
                  name="licenseNumber"
                />
              </AvGroup>
              <AvGroup>
                <Label id="licenseIssueDateLabel" for="driver-licenseIssueDate">
                  License Issue Date
                </Label>
                <AvField
                  id="driver-licenseIssueDate"
                  type="date"
                  className="form-control"
                  name="licenseIssueDate"
                />
              </AvGroup>
              <AvGroup>
                <Label id="licenseExpirationDateLabel" for="driver-licenseExpirationDate">
                  License Expiration Date
                </Label>
                <AvField
                  id="driver-licenseExpirationDate"
                  type="date"
                  className="form-control"
                  name="licenseExpirationDate"
                />
              </AvGroup>
              <AvGroup>
                <Label id="mpesaNumberLabel" for="driver-mpesaNumber">
                  M-PESA Number
                </Label>
                <AvField
                  id="driver-mpesaNumber"
                  type="text"
                  name="mpesaNumber"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 10, errorMessage: 'Maximum length is 10 digits' },
                    number: { value: true, errorMessage: 'This field should be a number.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="ownerFirstNameLabel" for="driver-ownerFirstName">
                  Vehicle Owner First Name
                </Label>
                <AvField
                  id="driver-ownerFirstName"
                  type="text"
                  name="ownerFirstName"
                />
              </AvGroup>
              <AvGroup>
                <Label id="ownerLastNameLabel" for="driver-ownerLastName">
                  Vehicle Owner Last Name
                </Label>
                <AvField
                  id="driver-ownerLastName"
                  type="text"
                  name="ownerLastName"
                />
              </AvGroup>
              <AvGroup>
                <Label id="ownerPhoneNumberLabel" for="driver-ownerPhoneNumber">
                  Vehicle Owner Phone Number
                </Label>
                <AvField
                  id="driver-ownerPhoneNumber"
                  type="text"
                  name="ownerPhoneNumber"
                  validate={{maxLength: { value: 15, errorMessage: 'Maximum length is 15 characters' }}}
                  />
              </AvGroup>

              <AvGroup>
                <Label id="ownerphone_number_2" for="driver-ownerPhoneNumber">
                  Phone Number 2
                </Label>
                <AvField
                  id="driver-phone_number_2"
                  type="text"
                  name="phone_number_2"
                  validate={{maxLength: { value: 15, errorMessage: 'Maximum length is 15 characters' }}}
                />
              </AvGroup>

              <AvGroup>
                <Label id="ownerphone_number_3" for="driver-ownerPhoneNumber">
                  Phone Number 3
                </Label>
                <AvField
                  id="driver-phone_number_3"
                  type="text"
                  name="phone_number_3"
                  validate={{maxLength: { value: 15, errorMessage: 'Maximum length is 15 characters' }}}
                />
              </AvGroup>


              <AvGroup>
                <Label id="commentsLabel" for="driver-comments">
                  Comments
                </Label>
                <AvField
                  id="driver-comments"
                  type="textarea"
                  name="comments"
                />
              </AvGroup>
              <AvGroup>
                <Label id="homeVillageLabel" for="driver-homeVillage">
                  Home Village
                </Label>
                <VillageAvTypeahead
                  labelKey="name"
                  valueKey="id"
                  data={villageOptionList(false)}
                  placeholder={isNew ? 'Home Village' : getVillageName(driver.homeVillageId, villages).full}
                  id="driver-homeVillage"
                  loading={villagesLoading}
                  input={{
                    id: 'driver-homeVillage',
                    name: 'homeVillageId',
                    required: true
                  }}
                />
                <AvFeedback>This field is required.</AvFeedback>
              </AvGroup>

              <AvGroup>
                <Label id="homeVillageLabel" for="driver-homeVillage">
                  Home Healthcare Facility Center
                </Label>
                <VillageAvTypeahead
                  labelKey="name"
                  valueKey="id"
                  data={hfOptionList(false)}
                  placeholder={isNew ? 'Home Healthcare Facility' : getHfName(driver.homeHealthcareFacilityId, allHealthfacility).full}
                  id="driver-homeHealthcareFacilityId"
                  loading={villagesLoading}
                  input={{
                    id: 'driver-homeVillage',
                    name: 'homeHealthcareFacilityId'
                  }}
                />
                <AvFeedback>This field is required.</AvFeedback>
              </AvGroup>

              <div style={neighborVillageSectionStyle}>
                <div style={neighborVillageLabelStyle}>
                  <span>Neighboring Villages</span>
                  <Button color="primary" id="add-neighbor-village" style={addNeighborVillageButtonStyle}
                          onClick={() => addNeighborVillage()}
                          disabled={!canAddNeighborVillage}>
                    <FontAwesomeIcon icon="plus" />
                    &nbsp; Add Neighbor Village
                  </Button>
                </div>
                {neighborVillageFields()}
              </div>


              <div style={neighborVillageSectionStyle}>
                <div style={neighborVillageLabelStyle}>
                  <span>Neighboring Healthcare Facility Center</span>
                  <Button color="primary" id="add-neighbor-village" style={addNeighborVillageButtonStyle}
                          onClick={() => addNeighborHf()}
                          disabled={!canAddNeighborHf}>
                    <FontAwesomeIcon icon="plus" />
                    &nbsp; Add Neighbor Healthcare Facility
                  </Button>
                </div>
                {neighborHfFields()}
              </div>

              <Button tag={Link} id="cancel-save" to="/driver" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  villages: storeState.village.entities,
  villagesLoading: storeState.village.loading,
  driver: storeState.driver.entity,
  loading: storeState.driver.loading,
  updating: storeState.driver.updating,
  updateSuccess: storeState.driver.updateSuccess,
  allHealthfacility: storeState.healthcareFacility.allFacility,
  healthcareFacility: storeState.healthcareFacility,
  countries: storeState.region.countries,
  vehicleTypes: storeState.vehicleType.entities,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getVillages,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getAllFacility,
  getCountries,
  getVehicleTypes
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverUpdate);
