import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IMMamaSettings } from 'app/shared/model/mmama-settings-model';
import { toCents } from 'app/shared/util/money-utils';
import { convertMinutesToSeconds, convertSecondsToMinutes } from 'app/shared/util/date-utils';
import {IGeofenceRoute} from "app/shared/model/geofence-route.model";

interface IGeofenceRouteUpdateParams {
  opened: boolean;
  toggle: () => void;
  mmamaSettings: IMMamaSettings;
  routeEntity: IGeofenceRoute;
  onSave: (route: IGeofenceRoute) => void;
}


export const RouteForm = (props: IGeofenceRouteUpdateParams) => {
  const [isActive, setIsActive] = useState(props.routeEntity?.isActive ?? false);
  const [distance, setDistance] = useState(props.routeEntity?.distance ?? 0);
  const [overridePrice, setOverridePrice] = useState(props.routeEntity?.overridePrice ?? 0);
  const [durationMinutes, setDurationMinutes] = useState(convertSecondsToMinutes(props.routeEntity?.durationSeconds));

  const { routeEntity, mmamaSettings } = props;

  // const handleClose = () => {
  //   props.history.push('/geofence-routes' + props.location.search);
  // };

  const handleIsActive = (event) => {
    setIsActive(event.target.value);
  };

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };

  const handleOverridePriceChange = (event) => {
    setOverridePrice(event.target.value);
  }

  const handleDurationMinutesChange = (event) => {
    setDurationMinutes(event.target.value);
  }

  function calculateRoutePrice(): number {
    return mmamaSettings[0]?.costKm ? distance * mmamaSettings[0].costKm : distance * mmamaSettings.costKm;
  }

  const saveEntity = (event, errors, values) => {




    if (errors.length === 0) {
      const entity = {
        ...routeEntity,
        ...values,
        endCouncilId:values.endCouncil.id ?? routeEntity.endCouncil.id,
        startCouncilId:values.startCouncil.id ?? routeEntity.startCouncil.id,
        overridePrice: toCents(values.overridePrice ?? routeEntity.overridePrice),
        durationSeconds: convertMinutesToSeconds(durationMinutes) ?? routeEntity.durationSeconds,
      };

      props.onSave(entity);
    }
  };

  return (
    <Modal className="modal-xl" isOpen={props.opened} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Edit a Geofence route</ModalHeader>
      <ModalBody id="mmamaApp.route.edit.form">
        <Row className="justify-content-center">
          <Col md="8">
            <AvForm id="edit-form" model={routeEntity} onSubmit={saveEntity}>
              {(
                <AvGroup>
                  <Label for="route-id">ID</Label>
                  <AvInput id="route-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              )}
              <AvGroup>
                <Label id="distanceLabel" for="route-distance">
                  Distance
                </Label>
                <AvField
                  id="route-distance"
                  type="text"
                  className="form-control"
                  name="distance"
                  onChange={handleDistanceChange}
                  validate={{
                    number: true,
                    pattern: {
                      value: '^(\\d+)?([.]?\\d{0,1})?$',
                      errorMessage: "Must enter a number with at most 1 decimal e.g. 123.4"
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="durationMinutesLabel" for="route-durationMinutes">
                  Duration (Minutes)
                </Label>
                <AvField id="route-durationMinutes" type="number" className="form-control" name="durationMinutes" onChange={handleDurationMinutesChange} value={durationMinutes}/>
              </AvGroup>
              <AvGroup>
                <Label id="priceLabel" for="calculatedPrice">
                  Current Price
                </Label>
                <AvField id="calculatedPrice" type="text" className="form-control" name="calculatedPrice" value={distance && calculateRoutePrice()} disabled />
              </AvGroup>
              <AvGroup>
                <Label id="overridePriceLabel" for="route-overridePrice">
                  Override Price
                </Label>
                <AvField
                  id="route-overridePrice"
                  type="text"
                  className="form-control"
                  name="overridePrice"
                  onChange={handleOverridePriceChange}
                  validate={{
                    number: true,
                    pattern: {
                      value: '^(\\d+)?([.]?\\d{0,1})?$',
                      errorMessage: "Must enter a number with at most 1 decimal e.g. 123.4"
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="route-description">
                  Description
                </Label>
                <AvField id="route-description" type="text" name="description" />
              </AvGroup>
              <AvGroup check>
                <Label id="isActiveLabel">
                  <AvInput
                    id="route-isActive"
                    type="checkbox"
                    className="form-check-input"
                    name="isActive"
                    checked={isActive}
                    value={isActive}
                    onChange={handleIsActive}
                  />
                  Active
                </Label>
              </AvGroup>
              <AvGroup>
                <Label for="route-startCouncil">Start</Label>
                {
                  routeEntity.startCouncil && (
                    <>
                      <AvField id="route-startCouncil" type="hidden" className="form-control" name="startCouncil.id" value={routeEntity.startCouncil.id} />
                      <AvField id="route-startCouncilName" type="text" className="form-control" name="startCouncil.name" value={routeEntity.startCouncil.name} disabled />
                    </>
                  )
                }
              </AvGroup>

              <AvGroup>
                <Label for="route-endCouncil">End</Label>
                {routeEntity.endCouncil && (
                  <>
                    <AvField id="route-endCouncil" type="hidden" className="form-control" name="endCouncil.id" value={routeEntity.endCouncil.id} />
                    <AvField id="route-endCouncilName" type="text" className="form-control" name="endCouncil.name" value={routeEntity.endCouncil.name} disabled />
                  </>
                )}
              </AvGroup>
            </AvForm>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          <FontAwesomeIcon icon="ban" />
          &nbsp; Back
        </Button>
        <Button color="primary" id="save-entity" type="submit" form="edit-form" disabled={false}>
          <FontAwesomeIcon icon="save" />
          &nbsp; Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RouteForm;
