import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, reset, updateEntity } from './transfer-reason.reducer';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import { IRegion } from 'app/shared/model/region.model';

export interface ITransferReasonUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{
  id: string
}> {
}

export const TransferReasonUpdate = (props: ITransferReasonUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const { transferReasonEntity, countries, userCountryId, loading, updating, hasUpdateAccess } = props;
  const [activeStatus, setActiveStatus] = useState(true);
  const [activeCountry, setActiveCountry] = useState<IRegion>({});
  const [regCountriesList, setRegCountriesList] = useState<IRegion[]>([]);

  const handleClose = () => {
    props.history.push('/transfer-reason');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    if ((isNew || transferReasonEntity.id) && userCountryId && countries && countries.length > 0) {
      let ac: IRegion;
      if (isNew) {
        ac = countries.find(country => country.id === (props.isAdmin ? countries[0].id : props.userCountryId));
      } else {
        ac = countries.find(country => country.id === transferReasonEntity?.countryId);
      }
      if (ac) {
        setActiveCountry(ac);
      }

      const allowedCountries = countries.filter((ele) => {
        return props.isAdmin ? 1 : ele.id === userCountryId;
      });
      setRegCountriesList(allowedCountries);
    }
  }, [countries, userCountryId, transferReasonEntity]);

  const onCountryChange = (event) => {
    const countryId = event.target.value;
    const newActiveCountry = countries.find(country => country.id === countryId);
    setActiveCountry(newActiveCountry);
  };

  useEffect(() => {
    if (!isNew) {
      setActiveStatus(transferReasonEntity.isActive);
    }
  }, [transferReasonEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...transferReasonEntity,
        ...values,
        countryId: activeCountry.id,
        isActive: activeStatus
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };


  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.transfer.reason.home.createOrEditLabel">{isNew ? 'Add' : 'Edit'} Transfer
            Reason</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : transferReasonEntity} onSubmit={saveEntity}>
              <br />
              {!isNew ? (
                <AvGroup>
                  <Label for="transfer-reason-id">ID</Label>
                  <AvInput id="transfer-reason-id" type="text" className="form-control" name="id" required
                           readOnly />
                </AvGroup>
              ) : null}

              <br />
              <div className="mt-3">
                <Row className="justify-content-left">
                  <Col md="6">
                    <AvGroup>
                      <Label id="isActive" for="transfer-reason-isActive">
                        Active Status
                      </Label>
                      <ButtonGroup style={{ marginLeft: '30px' }}>
                        <Button
                          color={activeStatus ? 'primary' : 'secondary'}
                          value={activeStatus}
                          onClick={(e) => {
                            setActiveStatus(true);
                          }}
                        >
                          Active
                        </Button>

                        <Button
                          color={!activeStatus ? 'primary' : 'secondary'}
                          value={activeStatus}
                          onClick={() => {
                            setActiveStatus(false);
                          }}
                        >
                          Inactive
                        </Button>
                      </ButtonGroup>
                    </AvGroup>
                  </Col>
                </Row>


                <br />
                <Row className="justify-content-left">
                  <Col md="6">
                    <AvGroup>
                      <Label id="enTransferReason" for="transfer-enTransferReason">
                        Transfer Reason (English)
                      </Label>
                      <AvField
                        id="transfer-enTransferReason"
                        type="text"
                        name="enTransferReason"
                        disabled={!hasUpdateAccess}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="stTransferReason" for="transfer-stTransferReason">
                        Transfer Reason (Local Language)
                      </Label>
                      <AvField
                        id="transfer-stTransferReason"
                        type="text"
                        name="stTransferReason"
                        disabled={!hasUpdateAccess}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="displayOrder" for="transfer-displayOrder">
                        Display Order
                      </Label>
                      <AvField
                        id="transfer-displayOrder"
                        type="number"
                        name="displayOrder"
                        disabled={!hasUpdateAccess}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label for="transfer-reason-country">Country</Label>
                      <AvInput
                        id="transfer-reason-country"
                        type="select"
                        className="form-control"
                        name="country"
                        disabled={isNew ? false : true}
                        value={isNew ? activeCountry?.id : transferReasonEntity?.countryId}
                        onChange={onCountryChange}>
                        {regCountriesList
                          && (props.isAdmin ? regCountriesList.map(country => (
                            <option value={country.id} key={country.id}>
                              {country.name}
                            </option>
                          )) : regCountriesList.map(country => (
                            props.userCountryId === country.id && <option value={country.id} key={country.id}>
                              {country.name}
                            </option>
                          )))}
                      </AvInput>
                    </AvGroup>
                  </Col>
                </Row>
              </div>

              <br />
              <Button tag={Link} id="cancel-save" to="/transfer-reason" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={!hasUpdateAccess || updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  transferReasonEntity: storeState.transferReason.entity,
  loading: storeState.transferReason.loading,
  updating: storeState.transferReason.updating,
  updateSuccess: storeState.transferReason.updateSuccess,
  countries: storeState.region.countries,
  userCountryId: storeState.authentication.userCountryId,
  hasUpdateAccess: hasAnyPermission(storeState.authentication.permissions, ['ADMIN', 'ROUTES_MODIFY']),
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getCountries,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransferReasonUpdate);
