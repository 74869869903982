import {IRegion} from "app/shared/model/region.model";

export interface ITransferReason {
  id?: string;
  enTransferReason?: string;
  stTransferReason?: string;
  countryId?: string;
  country?: IRegion;
  isActive?: boolean;
  displayOrder?: number;
}

export const defaultValue: Readonly<ITransferReason> = {};
