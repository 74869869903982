import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getReportMenu } from 'app/shared/reducers/authentication';
import { IRootState } from 'app/shared/reducers';
import * as jwt from 'jsonwebtoken';

function Report(props: any) {
    const { reportMenus } = props;
    const [iframeUrl, setIframeUrl] = useState('');
    const [iframeHeight, setIframeHeight] = useState(window.innerHeight ? window.innerHeight : '100%');

    // get jwt token @Todo move secret key to env variable
    const METABASE_SECRET_KEY = '00226479486318a53babc4a9b99be10ede6c2117cbf425a4fb418bfc356f7c84';
    const payload = {
        resource: { dashboard: 1 },
        params: {},
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };
    const token = jwt.sign(payload, METABASE_SECRET_KEY);

    useEffect(() => {
        if (reportMenus.length > 0) {
            for (const key in reportMenus) {
                if (props.match.params.type === reportMenus[key].slug) {
                   if(reportMenus[key].reportUrl) {
                     const reportUrl = reportMenus[key].reportUrl;
                     setIframeUrl(reportUrl);
                   }
                }
            }
        }
    }, [reportMenus]);

    const updateIframeHeight = () => {
        setIframeHeight(window.innerHeight ? window.innerHeight : '100%');
    };

    useEffect(() => {
        window.addEventListener('resize', updateIframeHeight);

        return () => {
            window.removeEventListener('resize', updateIframeHeight);
        };
    }, []);


    return (
        <div>
            {iframeUrl &&
                <iframe
                    src={iframeUrl}
                    frameBorder="0"
                    width={'100%'}
                    height={iframeHeight}
                    allowTransparency
                ></iframe>
            }
        </div>
    );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    reportMenus: authentication.reportMenus

});

const mapDispatchToProps = { getReportMenu };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Report);
