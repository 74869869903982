import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { toCents } from 'app/shared/util/money-utils';
import { convertMinutesToSeconds, convertSecondsToMinutes } from 'app/shared/util/date-utils';
import { IRegion } from 'app/shared/model/region.model';
import { createEntity, getEntity, reset, updateEntity } from './geofence-route.reducer';
import { getAllByType as getCountries, getCouncilsByCountry } from 'app/entities/region/region.reducer';
import { IRootState } from 'app/shared/reducers';
import mapboxgl from 'mapbox-gl';
import Map from 'app/shared/model/Map';
import { MAPBOX_ACCESS_TOKEN } from 'app/config/constants';
import { IGeofenceRoute } from 'app/shared/model/geofence-route.model';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { ButtonGroup } from '@material-ui/core';

interface IGeofenceRouteUpdateParams extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  opened: boolean;
  toggle: () => void;
  routeEntity: IGeofenceRoute;
  onSave: (route: IGeofenceRoute) => void;
}

const googleMapsApiKey = 'AIzaSyB1IEjh9nXMuohZPhEixzHRuy947RmytjE';

export const CreateRoute = (props: IGeofenceRouteUpdateParams) => {

  const [isActive, setIsActive] = useState(props.routeEntity?.isActive ?? false);
  // const [distance, setDistance] = useState(props.routeEntity?.distance ?? 0);
  const [pathLocation, setPathLocation] = useState({
    startLocation: { latitude: 0, longitude: 0 },
    endLocation: { latitude: 0, longitude: 0 }
  });

  const [activeCountry, setActiveCountry] = useState<IRegion>({});

  // const [durationMinutes, setDurationMinutes] = useState(convertSecondsToMinutes(props.routeEntity?.durationSeconds));
  const [durationMinutes, setDurationMinutes] = useState(0);

  const { routeEntity, mmamaSettings, councils, councilsLoading } = props;

  const [routeDistance, setRouteDistance] = useState(0.0);
  const [overridePrice, setOverridePrice] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');
  const [councilNames, setCouncilNames] = useState([]);
  const [clearValue, setClearValue] = useState(false);
  const [mapboxRouteObject, setMapboxRouteObject] = useState({});
  const [activeStatus, setActiveStatus] = useState(true);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

  const handleClose = () => {
    props.history.push('/geofence-route' + props.location.search);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const filterCouncilsByCountry = (countryId: string) => {
    const councilsNames = councils.map(item => ({
      short: item.name,
      latitude: item?.locationLat,
      longitude: item?.locationLon,
      full: (item.name + ' / ' + item?.parent?.name + ' / ' + item?.parent?.parent?.name),
      id: item.id
    }));
    setCouncilNames(councilsNames);
  };

  useEffect(() => {
    if (activeCountry && activeCountry.id && councils) {
      filterCouncilsByCountry(activeCountry?.id);
    }
  }, [activeCountry, councils]);

  const councilOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined, latitude: 0, longitude: 0 }]) : [];
    result = result.concat(councilNames);
    return result;
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    }
    props.getCountries();
  }, []);

  useEffect(() => {
    if (isNew) {
      const ac = props.countries.find(country => country.id === (props.isAdmin ? props.countries[0].id : props.userCountryId));
      setActiveCountry(ac);
      if (props?.countries?.length > 0) {
        props.getCouncilsByCountry(ac?.id);
      }
    } else {
      props.getEntity(props.match.params.id);
    }
  }, [props.countries]);

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  });

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...routeEntity,
        ...values,
        isActive: activeStatus,
        overridePrice: toCents(values.overridePrice ?? routeEntity.overridePrice),
        durationSeconds: convertMinutesToSeconds(durationMinutes) ?? routeEntity.durationSeconds
      };
      props.createEntity(entity);
      props.history.push('/geofence-routes' + props.location.search);
    }
  };
  const places = [
    { latitude: pathLocation.startLocation.latitude, longitude: pathLocation.startLocation.longitude },
    { latitude: pathLocation.endLocation.latitude, longitude: pathLocation.endLocation.longitude }
  ];

  const getRouteDistance = async () => {
    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude};${pathLocation.endLocation.longitude},${pathLocation.endLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
      { method: 'GET' }
    );
    const json = await query.json();
    setMapboxRouteObject(json.routes[0]);
    setRouteDistance((json.routes[0]?.distance) / 1000);
    setDurationMinutes(convertSecondsToMinutes(json.routes[0].duration.toString().split('.')[0]));
    const costPerKm = mmamaSettings.find(setting => setting.country.id === activeCountry.id);
    setCurrentPrice(((json.routes[0].distance) / 1000 * costPerKm.costKm).toString());
  };

  useEffect(() => {
    if (pathLocation.startLocation.latitude && pathLocation.endLocation.latitude &&
      pathLocation.startLocation.latitude !== 0 && pathLocation.endLocation.latitude !== 0) {
      getRouteDistance();
    }
  }, [pathLocation]);

  useEffect(() => {
    if (overridePrice) {
      setCurrentPrice('');
    } else if (pathLocation.startLocation.latitude && pathLocation.endLocation.latitude &&
      pathLocation.startLocation.latitude !== 0 && pathLocation.endLocation.latitude !== 0) {
      getRouteDistance();
    }
  }, [overridePrice]);

  const onCountryChange = (countryId: string) => {
    const ac = props.countries.find(country => country.id === countryId);
    props.getCouncilsByCountry(ac?.id);
    setActiveCountry(ac);
    setRouteDistance(0.0);
    setDurationMinutes(0);
    setOverridePrice('');
    setCurrentPrice('');
    setPathLocation({
      startLocation: { latitude: 0, longitude: 0 },
      endLocation: { latitude: 0, longitude: 0 }
    });
    setClearValue(!clearValue);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.payment.home.createOrEditLabel">Create a Geofence route </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
        </Col>
        <Col md="5">
          <AvForm id="edit-form" model={routeEntity} onSubmit={saveEntity}>
            <AvGroup>
              <Label for="route-countries">Select Country</Label>
              <AvInput
                id="route-countries"
                type="select"
                className="form-control"
                name="routeCountry"
                onChange={(e: any) => onCountryChange(e.target.value)}
                value={activeCountry?.id}
              >
                {regCountriesList
                  && regCountriesList.map(country => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
              </AvInput></AvGroup>
            <AvGroup>
              <Label for="route-startFacility">Start</Label>
              <VillageAvTypeahead
                labelKey="name"
                valueKey="id"
                data={councilOptionList(false)}
                placeholder={'Start'}
                id="driver-startCouncil"
                loading={councilsLoading}
                clearValue={clearValue}
                onChange={(data) => {
                  if (data) {
                    const locData = councilOptionList(false).find(ev => ev.id === data);
                    const locationData = { ...pathLocation };
                    locationData.startLocation.latitude = locData?.latitude;
                    locationData.startLocation.longitude = locData?.longitude;
                    setPathLocation(locationData);
                  }
                }}
                input={{
                  id: 'driver-homeCouncil',
                  name: 'startCouncilId'
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label for="route-endFacility">End</Label>
              <VillageAvTypeahead
                labelKey="name"
                valueKey="id"
                data={councilOptionList(false)}
                placeholder="End"
                id="driver-homeCouncil"
                loading={councilsLoading}
                clearValue={clearValue}
                onChange={(data) => {
                  if (data) {
                    const locData = councilOptionList(false).find(ev => ev.id === data);
                    const locationData = { ...pathLocation };
                    locationData.endLocation.latitude = locData.latitude;
                    locationData.endLocation.longitude = locData.longitude;
                    setPathLocation(locationData);
                  }
                }}
                input={{
                  id: 'driver-homeCouncil',
                  name: 'endCouncilId'
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label id="distanceLabel" for="route-distance">
                Distance
              </Label>
              <AvField
                id="route-distance"
                type="text"
                className="form-control"
                name="distance"
                value={routeDistance?.toFixed(1)}
                readOnly
                disable={true}
              />
            </AvGroup>
            <AvGroup>
              <Label id="durationMinutesLabel" for="route-durationMinutes">
                Duration (Minutes)
              </Label>
              <AvField
                id="route-durationMinutes"
                type="number" className="form-control"
                name="durationMinutes"
                value={durationMinutes.toFixed(1)}
                readOnly
                disable={true} />
            </AvGroup>
            <AvGroup>
              <Label id="priceLabel" for="currentPrice">
                Current Price
              </Label>
              <AvField id="currentPrice"
                       type="text"
                       className="form-control"
                       name="currentPrice"
                       value={currentPrice ? parseFloat(currentPrice).toFixed(2) : currentPrice}
                       readOnly
                       disable={true} />
            </AvGroup>
            <AvGroup>
              <Label id="overridePriceLabel" for="route-overridePrice">
                Override Price
              </Label>
              <AvField
                id="route-overridePrice"
                type="text"
                className="form-control"
                name="overridePrice"
                onChange={(e: any) => setOverridePrice(e.target.value)}
                value={overridePrice}
                validate={{
                  number: true,
                  pattern: {
                    value: '^(\\d+)?([.]?\\d{0,1})?$',
                    errorMessage: 'Must enter a number with at most 1 decimal e.g. 123.4'
                  }
                }}
              />
            </AvGroup>
            <br/>
            <AvGroup>
              <Label id="lastNameLabel" for="driver-lastName">
                Active Status
              </Label>
              <ButtonGroup style={{ marginLeft: '30px' }}>
                <Button
                  color={activeStatus ? 'primary' : 'secondary'}
                  value={activeStatus}
                  onClick={(e) => {
                    setActiveStatus(true);
                  }}
                >
                  Active
                </Button>
                <Button
                  color={!activeStatus ? 'primary' : 'secondary'}
                  value={activeStatus}
                  onClick={() => {
                    setActiveStatus(false);
                  }}
                >
                  Inactive
                </Button>
              </ButtonGroup>
            </AvGroup>
            <br/>
            <Button tag={Link} id="cancel-save" to="/geofence-routes" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">Back</span>
            </Button>
            &nbsp;
            <Button color="primary" id="save-entity" type="submit">
              <FontAwesomeIcon icon="save" />
              &nbsp; Save
            </Button>
          </AvForm>
        </Col>
        <Col md="7">
          <Map pathLocation={pathLocation} mapboxRouteObject={mapboxRouteObject} />
        </Col>
      </Row>
    </div>

  );
};
const mapStateToProps = (storeState: IRootState) => ({
  councils: storeState.region.councils,
  councilsLoading: storeState.region.loading,
  driver: storeState.geofenceRoute.entity,
  loading: storeState.geofenceRoute.loading,
  updating: storeState.geofenceRoute.updating,
  updateSuccess: storeState.geofenceRoute.updateSuccess,
  countries: storeState.region.countries,
  mmamaSettings: storeState.mmamaSettings.settings,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getCouncilsByCountry,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoute);
