import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { IRoute } from 'app/shared/model/route.model';
// import { IMMamaSettings } from 'app/shared/model/mmama-settings-model';
import { toCents } from 'app/shared/util/money-utils';
import { convertMinutesToSeconds, convertSecondsToMinutes } from 'app/shared/util/date-utils';
// import { getVillageName } from "app/entities/village/village";
// import GoogleMap from './../../shared/model/googlemap'
import { getVillagesByCountry as getVillages } from 'app/entities/village/village.reducer';
import { getAllFacility } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import { IRegion } from 'app/shared/model/region.model';
import { getEntity, updateEntity, createEntity, reset } from './route.reducer';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import { IRootState } from 'app/shared/reducers';
import mapboxgl from 'mapbox-gl';
import Map from 'app/shared/model/Map';
import { MAPBOX_ACCESS_TOKEN } from 'app/config/constants';
import { ButtonGroup } from '@material-ui/core';
import { hasAnyPermission } from 'app/shared/auth/permissions';

interface IRouteUpdateParams extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  opened: boolean;
  toggle: () => void;
  // mmamaSettings: IMMamaSettings;
  routeEntity: IRoute;
  villages: any;

  onSave: (route: IRoute) => void;
}

const googleMapsApiKey = 'AIzaSyB1IEjh9nXMuohZPhEixzHRuy947RmytjE';

export const CreateRoute = (props: IRouteUpdateParams) => {

  const [isActive, setIsActive] = useState(props.routeEntity?.isActive ?? false);
  // const [distance, setDistance] = useState(props.routeEntity?.distance ?? 0);
  const [pathLocation, setPathLocation] = useState({
    startLocation: { latitude: 0, longitude: 0 },
    endLocation: { latitude: 0, longitude: 0 }
  });
  const [startType, setStartType] = useState('HEALTHCARE_FACILITY');
  const [endType, setEndType] = useState('HEALTHCARE_FACILITY');

  const [activeCountry, setActiveCountry] = useState<IRegion>({});

  // const [durationMinutes, setDurationMinutes] = useState(convertSecondsToMinutes(props.routeEntity?.durationSeconds));
  const [durationMinutes, setDurationMinutes] = useState(0);

  const { routeEntity, mmamaSettings, villages, villagesLoading, allHealthfacility } = props;

  const [routeDistance, setRouteDistance] = useState(0.0);
  const [overridePrice, setOverridePrice] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');
  const [hfNames, setHfNames] = useState([]);
  const [villageNames, setVillageNames] = useState([]);
  const [clearValue, setClearValue] = useState(false);
  const [clearEndValue, setClearEndValue] = useState(false);
  const [mapboxRouteObject, setMapboxRouteObject] = useState({});
  const [activeStatus, setActiveStatus] = useState(true);

  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

  const handleClose = () => {
    props.history.push('/route' + props.location.search);
  };

  // const getHfName = (id: any, hfList: any) => {

  //   const hf = hfList.find(v => v.id === id);
  //   return !hf ?
  //     { short: '', full: '', council: '', district: '',latitude:0,longitude:0 }
  //     :
  //     {
  //       short: hf.name,
  //       council: hf?.council?.name,
  //       latitude: hf?.locationLat,
  //       longitude: hf?.locationLon,
  //       district: hf?.council?.parent?.name,
  //       full: (hf.name + ' / ' + hf?.council?.name + ' / ' + hf?.council?.parent?.name)
  //     };
  // }

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const handleIsActive = (event) => {
    setIsActive(event.target.value);
  };


  // const handleDistanceChange = (event) => {
  //   setDistance(event.target.value);
  // };

  // const handleOverridePriceChange = (event) => {
  //   // setOverridePrice(event.target.value);
  // }

  // const handleDurationMinutesChange = (event) => {
  //   setDurationMinutes(event.target.value);
  // }

  // function calculateRoutePrice(): number {
  //   return distance * mmamaSettings.costKm;
  // }

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (typeof props.errorMessage === 'string') {
      alert(props.errorMessage);
    }
  }, [props.errorMessage]);

  // const villageNames = villages ?
  //   villages.map(village => ({
  //     ...getVillageName(village.id, villages),
  //     id: village.id
  //   })) : [];

  // const hfNames = allHealthfacility ?
  //   allHealthfacility.map(hf => ({
  //     ...getHfName(hf.id, allHealthfacility),
  //     id: hf.id
  //   })) : [];

  const filterHFByCountry = (countryId: string) => {
    const hf1 = allHealthfacility.length > 0 ? allHealthfacility.filter(hf => hf?.council?.parent?.parent?.parent?.id === countryId) : [];
    const healthcareFacNames = hf1.map(item => ({
      short: item.name,
      council: item?.council?.name,
      latitude: item?.locationLat,
      longitude: item?.locationLon,
      district: item?.council?.parent?.name,
      full: (item.name + ' / ' + item?.council?.name + ' / ' + item?.council?.parent?.name),
      id: item.id
    }));
    // console.log("FILTER HEALTHCARE", healthcareFacNames);
    setHfNames(healthcareFacNames);
  };

  const filterVillageByCountry = (countryId: string) => {
    // const village1 = villages.length > 0 ? villages.filter(village => village?.council?.parent?.parent?.parent.id === countryId) : [];
    const villagesNames = villages.map(item => ({
      short: item.name,
      council: item?.council?.name,
      district: item?.council?.parent?.name,
      country: item?.council?.parent,
      latitude: item?.locationLat,
      longitude: item?.locationLon,
      full: (item.name + ' / ' + item?.council?.name + ' / ' + item?.council?.parent?.name),
      id: item.id
    }));
    // console.log("FILTER VILLAGES", villagesNames, villages);
    setVillageNames(villagesNames);
  };

  useEffect(() => {
    filterHFByCountry(activeCountry?.id);
    filterVillageByCountry(activeCountry?.id);
  }, [activeCountry, villages]);

  const hfOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined, latitude: 0, longitude: 0 }]) : [];
    result = result.concat(hfNames);
    return result;
  };
  const villageOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined, latitude: 0, longitude: 0 }]) : [];
    result = result.concat(villageNames);
    return result;
  };
  useEffect(() => {
    if (isNew) {
      props.reset();
    }
    props.getCountries();
  }, []);

  useEffect(() => {
    if (isNew) {
      const ac = props.countries.find(country => country.id === (props.isAdmin ? props.countries[0].id : props.userCountryId));
      setActiveCountry(ac);
      props.getAllFacility(ac?.id);
      props.getVillages(undefined, undefined, undefined, ac?.id);
    } else {
      props.getAllFacility();
      props.getEntity(props.match.params.id);
    }

  }, [props.countries]);

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  });

  const saveEntity = (event, errors, values) => {

    console.log('save entity', values);


    if (errors.length === 0) {
      const entity = {
        ...routeEntity,
        ...values,
        isActive: activeStatus,
        overridePrice: toCents(values.overridePrice ?? routeEntity.overridePrice),
        durationSeconds: convertMinutesToSeconds(durationMinutes) ?? routeEntity.durationSeconds,
        // endType: "HEALTHCARE_FACILITY",
        startVillageId: values.startType === 'HEALTHCARE_FACILITY' ? null : values.startVillageId,
        startFacilityId: values.startType === 'HEALTHCARE_FACILITY' ? values.startFacilityId : null,
        endVillageId: values.endType === 'HEALTHCARE_FACILITY' ? null : values.endVillageId,
        endFacilityId: values.endType === 'HEALTHCARE_FACILITY' ? values.endFacilityId : null
        // startFacilityId:values.startHfcenter,
        // endFacilityId:values.endHfcenter,

        /*    calculatedPrice: "11"
      description: "ssasa"
      distance: "22"
      durationMinutes: "222"
      durationSeconds: 13320
      endHfcenter: "ec6cb53e-41ab-11ec-87ac-02f146ec93af"
      endType: "HEALTHCARE_FACILITY"
      homeVillageId: "92c5770d-49f6-11eb-89d2-a85e45d05e66"
      isActive: true
      overridePrice: 111100
      startHfcenter: "9236b6f3-49f6-11eb-89d2-a85e45d05e66"

            description, distance,
             duration_seconds,
              end_facility_id,
              end_type,
               is_active, override_price, start_facility_id, start_type, start_village_id, id*/
      };

      console.log('Save Entity', entity);


      props.createEntity(entity);
      // props.history.push('/route' + props.location.search);
    }
  };
  const places = [
    { latitude: pathLocation.startLocation.latitude, longitude: pathLocation.startLocation.longitude },
    { latitude: pathLocation.endLocation.latitude, longitude: pathLocation.endLocation.longitude }
  ];

  const getRouteDistance = async () => {
    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude};${pathLocation.endLocation.longitude},${pathLocation.endLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
      { method: 'GET' }
    );

    const json = await query.json();
    setMapboxRouteObject(json.routes[0]);
    setRouteDistance((json.routes[0]?.distance) / 1000);
    setDurationMinutes(convertSecondsToMinutes(json.routes[0].duration.toString().split('.')[0]));

    const costPerKm = mmamaSettings.find(setting => setting.country.id === activeCountry.id);

    setCurrentPrice(((json.routes[0].distance) / 1000 * costPerKm.costKm).toString());
  };

  useEffect(() => {
    if (pathLocation.startLocation.latitude && pathLocation.endLocation.latitude &&
      pathLocation.startLocation.latitude !== 0 && pathLocation.endLocation.latitude !== 0) {
      getRouteDistance();
    }
  }, [pathLocation]);

  useEffect(() => {
    if (overridePrice) {
      setCurrentPrice('');
    } else if (pathLocation.startLocation.latitude && pathLocation.endLocation.latitude &&
      pathLocation.startLocation.latitude !== 0 && pathLocation.endLocation.latitude !== 0) {
      getRouteDistance();
    }
  }, [overridePrice]);

  const onCountryChange = (countryId: string) => {
    const ac = props.countries.find(country => country.id === countryId);
    props.getVillages(undefined, undefined, undefined, ac?.id);
    setActiveCountry(ac);
    setRouteDistance(0.0);
    setDurationMinutes(0);
    setOverridePrice('');
    setCurrentPrice('');
    setPathLocation({
      startLocation: { latitude: 0, longitude: 0 },
      endLocation: { latitude: 0, longitude: 0 }
    });
    setClearValue(!clearValue);
    setClearEndValue(!clearEndValue);
  };

  const onStartTypeChange = (text: string) => {
    setStartType(text);
    setRouteDistance(0.0);
    setDurationMinutes(0);
    setOverridePrice('');
    setCurrentPrice('');
    if (text === 'HEALTHCARE_FACILITY') {
      setClearEndValue(!clearEndValue);
      setEndType('HEALTHCARE_FACILITY');
      setPathLocation({
        startLocation: { latitude: 0, longitude: 0 },
        endLocation: { latitude: 0, longitude: 0 }
      });
    } else {
      setPathLocation({
        ...pathLocation,
        startLocation: { latitude: 0, longitude: 0 }
      });
    }
    setClearValue(!clearValue);
  };

  const onEndTypeChange = (text: string) => {
    setEndType(text);
    setRouteDistance(0.0);
    setDurationMinutes(0);
    setOverridePrice('');
    setCurrentPrice('');
    setPathLocation({
      ...pathLocation,
      endLocation: { latitude: 0, longitude: 0 }
    });
    setClearEndValue(!clearEndValue);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.payment.home.createOrEditLabel">Create a route</h2>
        </Col>

      </Row>

      <Row className="justify-content-center">
        <Col md="8">


        </Col>
        <Col md="5">
          <AvForm id="edit-form" model={routeEntity} onSubmit={saveEntity}>
            <AvGroup>
              <Label for="route-countries">Select Country</Label>
              <AvInput
                id="route-countries"
                type="select"
                className="form-control"
                name="routeCountry"
                onChange={(e: any) => onCountryChange(e.target.value)}
                value={activeCountry?.id}
              >
                {/* <option value={""}>{""}</option> */}
                {regCountriesList
                  && regCountriesList.map(country => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
              </AvInput></AvGroup>
            <AvGroup>
              <Label id="startTypeLabel" for="route-startType">
                Start Type
              </Label>
              <AvInput
                id="route-startType"
                type="select"
                className="form-control"
                name="startType"
                value={startType}
                onChange={(e) => {
                  // setStartType(e.target.value)
                  onStartTypeChange(e.target.value);
                }}

              >
                <option value="">select Start type</option>
                <option value="HEALTHCARE_FACILITY">HEALTHCARE_FACILITY</option>
                <option value="VILLAGE">VILLAGE</option>
              </AvInput>
            </AvGroup>

            {startType === 'HEALTHCARE_FACILITY' ?
              <AvGroup>
                <Label for="route-startFacility">Start Facility</Label>
                <VillageAvTypeahead
                  labelKey="name"
                  valueKey="id"
                  data={hfOptionList(false)}
                  placeholder={'Start Healthcare Facility'}
                  id="route-startFacility"
                  clearValue={clearValue}
                  onChange={(data) => {
                    // console.log("dataProps",data,hfOptionList(false).find(ev=> ev.id == data))
                    if (data) {
                      const locData = hfOptionList(false).find(ev => ev.id === data);
                      const locationData = { ...pathLocation };
                      locationData.startLocation.latitude = locData.latitude;
                      locationData.startLocation.longitude = locData.longitude;
                      setPathLocation(locationData);
                    }
                  }}
                  loading={villagesLoading}
                  input={{
                    id: 'route-startFacility',
                    name: 'startFacilityId'
                  }}
                />

              </AvGroup>
              :
              <AvGroup>
                <Label for="route-startVillage">Start Village</Label>
                <VillageAvTypeahead
                  labelKey="name"
                  valueKey="id"
                  data={villageOptionList(false)}
                  placeholder={'Start Village'}
                  id="route-startVillage"
                  clearValue={clearValue}
                  loading={villagesLoading}
                  onChange={(data) => {
                    // console.log("dataProps",data,hfOptionList(false).find(ev=> ev.id == data))
                    if (data) {
                      const locData = villageOptionList(false).find(ev => ev.id === data);
                      const locationData = { ...pathLocation };
                      locationData.startLocation.latitude = locData?.latitude;
                      locationData.startLocation.longitude = locData?.longitude;
                      setPathLocation(locationData);
                    }
                  }}
                  input={{
                    id: 'route-startVillage',
                    name: 'startVillageId'
                  }}
                />

              </AvGroup>

            }

            <AvGroup>
              <Label id="endTypeLabel" for="route-endType">
                End Type
              </Label>
              <AvInput
                id="route-endType"
                type="select"
                className="form-control"
                name="endType"
                value={endType}
                onChange={(e: any) => {
                  onEndTypeChange(e.target.value);
                }}

              >
                <option value="">select End type</option>
                <option value="HEALTHCARE_FACILITY">HEALTHCARE_FACILITY</option>
                {startType !== 'HEALTHCARE_FACILITY' && <option value="VILLAGE">VILLAGE</option>}
              </AvInput>
            </AvGroup>

            {endType === 'HEALTHCARE_FACILITY' ?
              <AvGroup>
                <Label for="route-endFacility">End Facility</Label>
                <VillageAvTypeahead
                  labelKey="name"
                  valueKey="id"
                  data={hfOptionList(false)}
                  placeholder="End Facility"
                  id="route-endFacility"
                  clearValue={clearEndValue}
                  loading={villagesLoading}
                  onChange={(data) => {
                    // console.log("dataProps",data,hfOptionList(false).find(ev=> ev.id == data))
                    if (data) {
                      const locData = hfOptionList(false).find(ev => ev.id === data);
                      const locationData = { ...pathLocation };
                      locationData.endLocation.latitude = locData.latitude;
                      locationData.endLocation.longitude = locData.longitude;
                      setPathLocation(locationData);
                    }

                  }}
                  input={{
                    id: 'route-endFacility',
                    name: 'endFacilityId'
                  }}
                />
              </AvGroup>
              :

              <AvGroup>
                <Label for="route-endVillage">End Village</Label>
                <VillageAvTypeahead
                  labelKey="name"
                  valueKey="id"
                  data={villageOptionList(false)}
                  placeholder={'End Village'}
                  id="route-endVillage"
                  clearValue={clearEndValue}
                  loading={villagesLoading}
                  onChange={(data) => {
                    // console.log("dataProps",data,hfOptionList(false).find(ev=> ev.id == data))
                    if (data) {
                      const locData = villageOptionList(false).find(ev => ev.id === data);
                      const locationData = { ...pathLocation };
                      locationData.endLocation.latitude = locData?.latitude;
                      locationData.endLocation.longitude = locData?.longitude;
                      setPathLocation(locationData);
                    }
                  }}
                  input={{
                    id: 'route-endVillage',
                    name: 'endVillageId'
                  }}
                />

              </AvGroup>

            }

            {/* pathLocation.startLocation.latitude  && pathLocation.startLocation.longitude

          && pathLocation.endLocation.latitude  && pathLocation.endLocation.longitude ?
            <Map
              googleMapURL={
                'https://maps.googleapis.com/maps/api/js?key=' +
                googleMapsApiKey +
                '&libraries=geometry,drawing,places'
              }
              markers={places}
              loadingElement={<div style={{ height: `100%`, position: 'static' }} />}
              containerElement={<div style={{ height: "80vh" }} />}
              mapElement={<div style={{ height: `100%`, display: 'block' }} />}
              defaultCenter={{ lat: 25.798939, lng: -80.291409 }}
              defaultZoom={20}
            /> : ''*/}


            <AvGroup>
              <Label id="distanceLabel" for="route-distance">
                Distance
              </Label>

              <AvField
                id="route-distance"
                type="text"
                className="form-control"
                name="distance"
                // disabled={true}
                //  onChange={handleDistanceChange}
                // value={getDistanceBetweenTwoCordinates(pathLocation.startLocation.latitude,pathLocation.startLocation.longitude,pathLocation.endLocation.latitude,pathLocation.endLocation.longitude).toFixed(1)}
                value={routeDistance?.toFixed(1)}
                // validate={{
                //     number: true,
                //     pattern: {
                //       value: '^(\\d+)?([.]?\\d{0,1})?$',
                //       errorMessage: "Must enter a number with at most 1 decimal e.g. 123.4"
                //     }
                //   }}
                readOnly
                disable={true}
              />

            </AvGroup>
            <AvGroup>
              <Label id="durationMinutesLabel" for="route-durationMinutes">
                Duration (Minutes)
              </Label>
              <AvField id="route-durationMinutes" type="text" className="form-control" name="durationMinutes"
                // onChange={handleDurationMinutesChange}
                       value={durationMinutes.toFixed(1)} readOnly disable={true} />
            </AvGroup>
            <AvGroup>
              <Label id="priceLabel" for="currentPrice">
                Current Price
              </Label>
              <AvField id="currentPrice" type="text" className="form-control" name="currentPrice"
                       value={currentPrice ? parseFloat(currentPrice).toFixed(2) : currentPrice} readOnly
                       disable={true} />
            </AvGroup>
            <AvGroup>
              <Label id="overridePriceLabel" for="route-overridePrice">
                Override Price
              </Label>
              <AvField
                id="route-overridePrice"
                type="text"
                className="form-control"
                name="overridePrice"
                onChange={(e: any) => setOverridePrice(e.target.value)}
                value={overridePrice}
                validate={{
                  number: true,
                  pattern: {
                    value: '^(\\d+)?([.]?\\d{0,1})?$',
                    errorMessage: 'Must enter a number with at most 1 decimal e.g. 123.4'
                  }
                }}
              />
            </AvGroup>
            {/* <AvGroup>
              <Label id="descriptionLabel" for="route-description">
                Description
              </Label>
              <AvField id="route-description" type="text" name="description" />
            </AvGroup> */}
            <br />
            <AvGroup>
              <Label id="lastNameLabel" for="driver-lastName">
                Active Status
              </Label>
              <ButtonGroup style={{ marginLeft: '30px' }}>
                <Button
                  color={activeStatus ? 'primary' : 'secondary'}
                  value={activeStatus}
                  onClick={(e) => {
                    setActiveStatus(true);
                  }}
                >
                  Active
                </Button>
                <Button
                  color={!activeStatus ? 'primary' : 'secondary'}
                  value={activeStatus}
                  onClick={() => {
                    setActiveStatus(false);
                  }}
                >
                  Inactive
                </Button>
              </ButtonGroup>
            </AvGroup>
            <br />

            <Button tag={Link} id="cancel-save" to="/route" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">Back</span>
            </Button>
            &nbsp;
            <Button color="primary" id="save-entity" type="submit">
              <FontAwesomeIcon icon="save" />
              &nbsp; Save
            </Button>
          </AvForm>
        </Col>
        <Col md="7">
          <Map pathLocation={pathLocation} mapboxRouteObject={mapboxRouteObject} />
        </Col>
      </Row>
    </div>

  );
};
const mapStateToProps = (storeState: IRootState) => ({
  villages: storeState.village.entities,
  villagesLoading: storeState.village.loading,
  driver: storeState.route.entity,
  loading: storeState.route.loading,
  updating: storeState.route.updating,
  updateSuccess: storeState.route.updateSuccess,
  errorMessage: storeState.route.errorMessage,
  allHealthfacility: storeState.healthcareFacility.allFacility,
  healthcareFacility: storeState.healthcareFacility,
  countries: storeState.region.countries,
  mmamaSettings: storeState.mmamaSettings.settings,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getVillages,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getAllFacility,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoute);
