

export interface IPaymentBreakdown {
  id?: string;
  districtId?: string;
  startDate?: string;
  endDate?: string;
  govPercentage?: number;
}

export const defaultValue: Readonly<IPaymentBreakdown> = {};
