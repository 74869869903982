import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getCouncils } from 'app/entities/council/council.reducer';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import { createEntity, getEntity, reset, updateEntity } from './healthcare-facility.reducer';
import { IRegion } from 'app/shared/model/region.model';
import { getCouncilName } from 'app/entities/region/region';
import axios from 'axios';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { hasAnyPermission } from 'app/shared/auth/permissions';

export interface IHealthcareFacilityUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const HealthcareFacilityUpdate = (props: IHealthcareFacilityUpdateProps) => {
  const [councilId, setCouncilId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  // const [countryId, setCountryId] = useState(null)
  const [activeCountry, setActiveCountry] = useState<IRegion>({});
  const [councils, setCouncils] = useState([]);
  const [councilNames, setCouncilNames] = useState([]);
  const [council, setCouncil] = useState<IRegion>({ 'name': '' });
  const [healthcareFacilityCountry, setHealthcareFacilityCountry] = useState<IRegion>({});
  const [activeStatus, setActiveStatus] = useState(true);
  const [activeHealthcareFacilityType, setHealthcareFacilityType] = useState(null);
  const { healthcareFacilityEntity, loading, updating } = props;
  const [hfLevels, setHfLevels] = useState([]);
  const [isNcu, setIsNcu] = useState(true);

  const handleClose = () => {
    props.history.push('/healthcare-facility' + props.location.search);
  };

  const healthcareFacilityType = [{ id: 'BEMONC', value: 'BEMONC' }, { id: 'CEMONC', value: 'CEMONC' }];
  const councilOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined }]) : [];
    result = result.concat(councilNames);
    return result;
  };


  const fetchCouncilsForCountry = (countryId) => {
    axios.get<IRegion[]>(`api/regions/from-parent/${countryId}/COUNCIL`)
      .then((response) => {
          setCouncils(response.data);
          setCouncilNames(response.data ?
            response.data.map(c => ({
              ...getCouncilName(c.id, response.data),
              id: c.id
            })) : []);
        },
        (error) => {
          // TODO: handle error here
        });
  };

  const onCountryChange = (event) => {
    const countryId = event.target.value;
    const newActiveCountry = props.countries.find(country => country.id === countryId);
    setActiveCountry(newActiveCountry);
    fetchCouncilsForCountry(countryId);
  };

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  });

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getCountries();
  }, []);


  useEffect(() => {
    const hfCountry = healthcareFacilityEntity?.council?.parent?.parent?.parent;
    if (hfCountry) {
      setActiveCountry(hfCountry);
      setHealthcareFacilityCountry(hfCountry);
      fetchCouncilsForCountry(hfCountry.id);
    }
    setActiveStatus(props?.healthcareFacilityEntity?.activated);
    setIsNcu(props?.healthcareFacilityEntity?.isNcu);
  }, [props.healthcareFacilityEntity]);

  useEffect(() => {
    if (isNew) {
      const ac = props.countries.find(country => country.id === (props.isAdmin ? props.countries[0].id : props.userCountryId));
      setActiveCountry(ac);
      fetchCouncilsForCountry(ac?.id);
    }
  }, [props.countries]);

  useEffect(() => {
    // console.log("ACTIVE COUNTRY> > ",activeCountry);
    axios.get(`api/level?countryId=${activeCountry.id}`).then((response) => {
      console.log(response.data);
      setHfLevels(response.data);
    }).catch(error => console.log(error));
  }, [activeCountry]);

  useEffect(() => {
    console.log('healthcareFacilityEntity', healthcareFacilityEntity);
    // setActivePaymentMathod({id:healthcareFacilityEntity.type, value:healthcareFacilityEntity.type})
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    const facilityCouncil = councils.find(c => c.id === values['council'].id);
    if (errors.length === 0) {
      const entity = {
        ...healthcareFacilityEntity,
        ...values,
        activated: activeStatus,
        isNcu,
        council: facilityCouncil
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mmamaApp.healthcareFacility.home.createOrEditLabel">Create or edit a Healthcare Facility</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : healthcareFacilityEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="healthcare-facility-id">ID</Label>
                  <AvInput id="healthcare-facility-id" type="text" className="form-control" name="id"
                           required
                           readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="healthcare-facility-name">
                  Name
                </Label>
                <AvField
                  id="healthcare-facility-name"
                  type="text"
                  name="name"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="driver-lastName">
                  Active Status
                </Label>


                <ButtonGroup style={{ marginLeft: '30px' }}>

                  <Button
                    color={activeStatus ? 'primary' : 'secondary'}
                    value={activeStatus}
                    onClick={(e) => {
                      setActiveStatus(true);
                    }}
                  >
                    Active
                  </Button>


                  <Button
                    color={!activeStatus ? 'primary' : 'secondary'}
                    value={activeStatus}
                    onClick={() => {
                      setActiveStatus(false);
                    }}
                  >
                    Inactive
                  </Button>

                </ButtonGroup>
              </AvGroup>

              {regCountriesList && regCountriesList.length > 0 && (
                <AvGroup>
                  <Label for="hf-country">Country</Label>
                  <AvInput id="hf-country" type="select" className="form-control" name="country"
                           disabled={isNew ? false : true}
                           value={isNew ? activeCountry?.id : healthcareFacilityCountry.id}
                           onChange={onCountryChange}>
                    {regCountriesList
                      ? regCountriesList.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
              )
              }


              {(councils && councils.length > 0) && (
                <AvGroup>
                  <Label id="hfCouncilLabel" for="healthcare-facility-council">
                    Council
                  </Label>
                  <VillageAvTypeahead
                    labelKey="name"
                    valueKey="id"
                    data={councilOptionList(false)}
                    placeholder={isNew ? 'Council' : getCouncilName(healthcareFacilityEntity?.council?.id, councils).full}
                    id="healthcare-facility-council"
                    loading={false}
                    input={{
                      id: 'healthcare_facility_council',
                      name: 'council.id',
                      required: true
                    }}
                  />
                  <AvFeedback>This field is required.</AvFeedback>
                </AvGroup>)
              }


              {/* <AvGroup>
                <Label id="levelLabel" for="healthcare-facility-level">
                  Level
                </Label>
                <AvField
                  id="healthcare-facility-level"
                  type="number"
                  className="form-control"
                  name="level"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    number: {value: true, errorMessage: 'This field should be a number.'},
                  }}
                  min={0}
                  step={1}
                />
              </AvGroup> */}
              <AvGroup>
                <Label id="levelLabel" for="healthcare-facility-level">Level</Label>
                <AvInput
                  id="healthcare-facility-level"
                  type="select"
                  className="form-control"
                  name="level"
                  value={healthcareFacilityCountry.level}
                >
                  {hfLevels && hfLevels.map(level => (
                    <option value={level.level} key={level.level}>
                      {level.level} - {level.en_description}
                    </option>
                  ))}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="facilityCodeLabel" for="healthcare-facility-facilityCode">
                  Facility Code
                </Label>
                <AvField id="healthcare-facility-facilityCode" type="text" name="facilityCode" />
              </AvGroup>
              <AvGroup>
                <Label id="contactNameLabel" for="healthcare-facility-contactName">
                  Contact Name
                </Label>
                <AvField id="healthcare-facility-contactName" type="text" name="contactName" />
              </AvGroup>
              <AvGroup>
                <Label id="phoneNumberLabel" for="healthcare-facility-phoneNumber">
                  Phone Number
                </Label>
                <AvField id="healthcare-facility-phoneNumber" type="text" name="phoneNumber" />
              </AvGroup>
              <AvGroup>
                <Label id="ownerphone_number_2" for="driver-ownerPhoneNumber">
                  Phone Number 2
                </Label>
                <AvField
                  id="driver-phone_number_2"
                  type="text"
                  name="phone_number_2"
                />
              </AvGroup>

              <AvGroup>
                <Label id="ownerphone_number_3" for="driver-ownerPhoneNumber">
                  Phone Number 3
                </Label>
                <AvField
                  id="driver-phone_number_3"
                  type="text"
                  name="phone_number_3"
                />
              </AvGroup>
              <AvGroup>
                <Label id="locationLatLabel" for="healthcare-facility-locationLat">
                  Latitude
                </Label>
                <AvField id="healthcare-facility-locationLat" type="string" className="form-control"
                         name="locationLat" />
              </AvGroup>
              <AvGroup>
                <Label id="locationLonLabel" for="healthcare-facility-locationLon">
                  Longitude
                </Label>
                <AvField id="healthcare-facility-locationLon" type="string" className="form-control"
                         name="locationLon" />
              </AvGroup>

              <AvGroup>
                <Label id="countryLabel" for="countryLabel">
                  Healthcare Facility Type
                </Label>
                <AvInput id="type" type="select" className="form-control"
                         name="type" value={activeHealthcareFacilityType?.id} onChange={(event) => {

                  const methodId = event.target.value;
                  const newActiveHealthcareFacilityType = healthcareFacilityType.find(method => method.id === methodId);
                  setHealthcareFacilityType(newActiveHealthcareFacilityType);
                }}>
                  <option value={''}>
                    Select Healthcare Facility Type
                  </option>
                  {healthcareFacilityType.map(method => (
                    <option value={method.id} key={method.id}>
                      {method.value}
                    </option>
                  ))
                  }

                </AvInput>
              </AvGroup>

              <AvGroup>
                <Label id="isNcuLabel" for="healthcare-facility-isNcu">
                  IsNCU
                </Label>
                <ButtonGroup style={{ marginLeft: '30px' }}>
                  <Button
                    color={isNcu ? 'primary' : 'secondary'}
                    value={isNcu}
                    onClick={(e) => {
                      setIsNcu(true);
                    }}
                  >
                    True
                  </Button>
                  <Button
                    color={!isNcu ? 'primary' : 'secondary'}
                    value={isNcu}
                    onClick={() => {
                      setIsNcu(false);
                    }}
                  >
                    False
                  </Button>
                </ButtonGroup>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/healthcare-facility" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  countries: storeState.region.countries,
  healthcareFacilityEntity: storeState.healthcareFacility.entity,
  loading: storeState.healthcareFacility.loading,
  updating: storeState.healthcareFacility.updating,
  updateSuccess: storeState.healthcareFacility.updateSuccess,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getCouncils,
  getCountries,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareFacilityUpdate);
